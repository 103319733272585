import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Button,
  alpha,
  LinearProgress,
  Snackbar,
  useTheme
} from '@mui/material';
import { Image } from 'common/components/Image';
import { Mark } from 'common/components/Mark';
import { useGetProjectQuery } from 'project/queries/getProject';
import { MotionBox } from 'common/components/MotionBox';
import { FileUploadPurpose } from 'common/types/graphql-types';
import { useUpdateProjectMutation } from 'project/mutations/updateProject';
import { useUploadsStore, Upload } from 'common/state/uploads';
import { Alert } from '@mui/material';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { PROJECT_UPDATED } from 'project/events';

export const CoverImage: FC<{ src?: null | string }> = ({ src }) => {
  return (
    <Box
      id='coverImage'
      sx={{
        position: 'relative',
        width: '100%',
        paddingBottom: '100%',
        borderRadius: 2.5,
        overflow: 'hidden',
        backgroundColor: '#524D6F'
      }}
    >
      {!!src ? (
        <Image priority layout={'fill'} objectFit={'cover'} src={src} />
      ) : (
        <Mark
          sx={{
            width: '50%',
            maxWidth: 100,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}
    </Box>
  );
};

const duration = 0.3;
const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: duration }
  }
};

export const CoverImageUpload: FC<{ id: string }> = ({ id }) => {
  const analytics = useAnalytics();
  const theme = useTheme();
  const { data } = useGetProjectQuery({ variables: { id } });
  const { uploadFile, uploads } = useUploadsStore();
  const [uploadId, setUploadId] = useState<string | null>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [updateProjectMutation] = useUpdateProjectMutation();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);

  const upload = useMemo(() => {
    if (!uploadId) {
      return {} as Upload;
    }
    return uploads?.[uploadId] || {};
  }, [uploads, uploadId]);

  const toggleHover = useCallback(
    (evt: MouseEvent<HTMLElement>) => {
      setIsHovering(evt.type === 'mouseenter');
    },
    [isHovering]
  );

  const handleClick = useCallback(async () => {
    inputRef?.click();
  }, [inputRef]);

  const handleChange = useCallback(
    async (evt: ChangeEvent<HTMLInputElement>) => {
      const file = evt.currentTarget.files?.[0];
      if (!file) {
        return;
      }

      const id = await uploadFile({
        file,
        purpose: FileUploadPurpose.ProjectArtwork
      });
      setUploadId(id);
    },
    []
  );

  useEffect(() => {
    if (upload?.progress === 100) {
      updateProjectMutation({
        variables: {
          id,
          artwork: upload?.filename
        }
      });
      analytics.capture(PROJECT_UPDATED, {
        project: id,
        artwork: upload?.filename
      });
    }
    if (upload?.error) {
      setError(upload?.error);
    }
  }, [upload]);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return (
    <Box
      position={'relative'}
      onMouseEnter={!data?.album?.isDisabled ? toggleHover : undefined}
      onMouseLeave={!data?.album?.isDisabled ? toggleHover : undefined}
    >
      <Snackbar open={!!error} autoHideDuration={6000} onClose={clearError}>
        <Alert severity='error'>{upload.error}</Alert>
      </Snackbar>
      <CoverImage src={data?.album.artwork} />
      {((isHovering && !!data?.album?.canEdit) || upload?.progress != null) && (
        <MotionBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 2.5,
            inset: 0,
            backdropFilter: 'blur(3px)',
            background: alpha(theme.palette.common.black, 0.4),
            position: 'absolute'
          }}
          variants={variants}
          initial='initial'
          animate='enter'
          exit='exit'
        >
          {upload?.progress != null ? (
            <Box width={'80%'}>
              <LinearProgress
                color={'primary'}
                variant='determinate'
                value={upload.progress}
              />
            </Box>
          ) : (
            <Button
              aria-controls='update-image'
              variant={'outlined'}
              onClick={handleClick}
            >
              Update Image
            </Button>
          )}
        </MotionBox>
      )}
      <Box display={'none'}>
        <input
          onChange={handleChange}
          ref={setInputRef}
          accept={['image/png', 'image/jpg', 'image/jpeg'].join(',')}
          type={'file'}
        />
      </Box>
    </Box>
  );
};
