import { AlertColor } from '@mui/material';
import { v4 } from 'uuid';
import create from 'zustand';

export interface Snackbar {
  id: string;
  severity: AlertColor;
  duration?: number;
  text: string;
}

export interface SnackbarsState {
  snackbars: Snackbar[] | null;

  clearSnackbars: () => void;
  addSnackbar: (snackbar: Omit<Snackbar, 'id'>) => string;
  removeSnackbar: (id: string) => void;
}

export const useSnackbarsStore = create<SnackbarsState>((set, get) => ({
  snackbars: [],

  clearSnackbars: () => set({ snackbars: [] }),
  addSnackbar: (snackbar) => {
    const id = v4();
    set({ snackbars: [...(get()?.snackbars || []), { id, ...snackbar }] });
    return id;
  },
  removeSnackbar: (id) => {
    set({
      snackbars: (get()?.snackbars || [])?.filter((snackbar) => snackbar.id !== id)
    })
  }
})) 