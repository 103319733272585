import { GetPageQuery } from 'cms/queries/getPage';
import { GlobalStateAction } from 'common/hooks/useGlobalState';
import { set, get, insert, del } from 'object-path-immutable';

export type PageState = GetPageQuery['page'] | null;

export const START_EDITING = 'start-editing';
export const EDIT_COMPONENT = 'edit-component';
export const STOP_EDITING = 'stop-editing';
export const MOVE_COMPONENT = 'move-component';
export const REMOVE_COMPONENT = 'remove-component';

export const reducer = (state: PageState, action: GlobalStateAction) => {
  switch (action.type) {
    case START_EDITING: {
      return {
        ...action.payload
      };
    }
    case EDIT_COMPONENT: {
      return set(state, action.payload?.path as string, action.payload?.value);
    }
    case REMOVE_COMPONENT: {
      return del(state, action.payload?.path as string);
    }
    case MOVE_COMPONENT: {
      const item = get(
        state,
        `${action.payload?.path}.${action?.payload?.start}`
      );

      return insert(
        del(state, `${action.payload?.path}.${action?.payload?.start}`),
        action?.payload?.path as string,
        item,
        action?.payload?.end as number
      );
    }
    case STOP_EDITING: {
      return null;
    }
    default:
      return state;
  }
};
