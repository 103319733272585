import {
  Close,
  Pause,
  PlayArrow,
  SkipNext,
  SkipPrevious
} from '@mui/icons-material';
import { AppBar, Box, Grid, Hidden, IconButton } from '@mui/material';
import { Link } from 'common/components/Link';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { imageLoader } from 'common/methods/imageLoader';
import { PREVIEW_TRACKS_NEXT, PREVIEW_TRACKS_PREV } from 'project/events';
import { projectDetailRoute } from 'project/routes';
import { useGlobalPlayerStore } from 'project/state/globalPlayer';
import { useCallback, useEffect } from 'react';
import { SliderPlayer } from 'track/components/SliderPlayer';
import { getRevisionSource } from 'track/methods/getRevisionSource';
import { useGetTrackLazyQuery } from 'track/queries/getTrack';
import { trackDetailRoute } from 'track/routes';
import { CoverImage } from './CoverImage';

export const GlobalPlayer = () => {
  const analytics = useAnalytics();
  const isPlaying = useGlobalPlayerStore(
    useCallback((state) => state.isPlaying, [])
  );
  const play = useGlobalPlayerStore(useCallback((state) => state.play, []));
  const pause = useGlobalPlayerStore(useCallback((state) => state.pause, []));
  const seek = useGlobalPlayerStore(useCallback((state) => state.seek, []));
  const source = useGlobalPlayerStore(useCallback((state) => state.source, []));
  const track = useGlobalPlayerStore(useCallback((state) => state.track, []));
  const reset = useGlobalPlayerStore(useCallback((state) => state.reset, []));
  const setTrack = useGlobalPlayerStore(
    useCallback((state) => state.setTrack, [])
  );
  const setSource = useGlobalPlayerStore(
    useCallback((state) => state.setSource, [])
  );
  const setOnError = useGlobalPlayerStore(
    useCallback((state) => state.setOnError, [])
  );
  const setOnEnded = useGlobalPlayerStore(
    useCallback((state) => state.setOnEnded, [])
  );
  const setOnPrev = useGlobalPlayerStore(
    useCallback((state) => state.setOnPrev, [])
  );
  const setOnNext = useGlobalPlayerStore(
    useCallback((state) => state.setOnNext, [])
  );

  const [getTrack, { data, refetch }] = useGetTrackLazyQuery();

  useEffect(() => {
    if (!track) {
      return;
    }
    getTrack({
      variables: {
        id: track
      }
    });
  }, [track]);

  const playNext = useCallback(() => {
    if (!data?.track?.next?.id) {
      return;
    }
    analytics.capture(PREVIEW_TRACKS_NEXT, {
      project: data?.track?.album?.id,
      track: data?.track?.id,
      next: data?.track?.next?.id
    });
    setTrack(data?.track?.next?.id);
    play();
  }, [data?.track?.next]);

  const playPrev = useCallback(() => {
    if (!data?.track?.prev?.id) {
      return;
    }

    analytics.capture(PREVIEW_TRACKS_PREV, {
      project: data?.track?.album?.id,
      track: data?.track?.id,
      previous: data?.track?.prev?.id
    });
    setTrack(data?.track?.prev?.id);
  }, [data?.track?.prev]);

  useEffect(() => {
    setOnEnded(playNext);
    setOnNext(playNext);
  }, [playNext]);

  useEffect(() => {
    setOnPrev(playPrev);
  }, [playPrev]);

  useEffect(() => {
    if (!refetch) {
      return;
    }
    setOnError(() => refetch());
  }, [refetch]);

  useEffect(() => {
    if (!data?.track?.primaryRevision || !track) {
      return;
    }
    if (navigator?.mediaSession) {
      const track = data?.track;
      navigator.mediaSession.metadata = new MediaMetadata({
        title: track.title,
        artist: track?.album?.artist || undefined,
        album: track?.album?.title,
        artwork: track?.album?.artwork
          ? [
              {
                src: imageLoader({ src: track?.album?.artwork, width: 240 })
              }
            ]
          : undefined
      });
    }
    const src = getRevisionSource(data.track.primaryRevision);
    setSource(src);
    seek(0);
  }, [data?.track, track]);

  if (!source) {
    return null;
  }

  return (
    <AppBar
      id='globalPlayer'
      component={'aside'}
      position={'fixed'}
      sx={{ top: 'unset', bottom: 0 }}
    >
      <Grid
        container
        justifyContent={'space-between'}
        paddingX={1}
        paddingTop={1}
      >
        <Grid item xs={12} md={4}>
          <Box display={'flex'} position={'relative'} paddingRight={6}>
            <Box width={64} marginRight={2}>
              <Link
                color={'textPrimary'}
                underline='always'
                href={projectDetailRoute(data?.track?.album?.id as string)}
              >
                <CoverImage src={data?.track?.album?.artwork} />
              </Link>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Link
                underline='always'
                color={'textPrimary'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
                href={trackDetailRoute(data?.track?.id as string)}
              >
                {data?.track?.title}
              </Link>
              <Link
                color={'textPrimary'}
                underline='always'
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
                href={projectDetailRoute(data?.track?.album?.id as string)}
              >
                {data?.track?.album?.artist}
              </Link>
            </Box>
            <Hidden mdUp>
              <Box
                position={'absolute'}
                right={8}
                top={'50%'}
                sx={{ transform: 'translateY(-50%)' }}
              >
                <IconButton size={'small'} onClick={reset}>
                  <Close fontSize={'small'} />
                </IconButton>
              </Box>
            </Hidden>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display={'flex'} justifyContent={'center'}>
            <IconButton onClick={playPrev}>
              <SkipPrevious />
            </IconButton>
            <IconButton onClick={isPlaying ? pause : play}>
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <IconButton onClick={playNext}>
              <SkipNext />
            </IconButton>
          </Box>
          <SliderPlayer store='globalplayer' variant={'inline'} />
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={1}
            md={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Box>
              <IconButton size={'small'} onClick={reset}>
                <Close fontSize={'small'} />
              </IconButton>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </AppBar>
  );
};
