import React, { useReducer, FC, Reducer, ReducerState } from 'react';
import { reducer as pageStateReducer, reducer } from 'cms/store/pageStore';
import { GlobalStateContext } from 'common/hooks/useGlobalState';

interface GlobalStateProvicerProps {
  cookies: unknown;
}

function combineReducers<R extends Reducer<any, any>>(reducers: {
  [K in keyof ReducerState<R>]: [
    Reducer<ReducerState<R>[K], any>,
    ReducerState<R>[K]
  ];
}) {
  const reducerKeys = Object.keys(reducers);

  const [globalState, finalReducers] = Object.entries(reducers).reduce<
    [ReducerState<R>, Record<string, R>]
  >(
    (acc, [key, value]) => {
      acc[0][key] = value[1];
      acc[1][key] = value[0];
      return acc;
    },
    [{} as ReducerState<R>, {}]
  );

  return [
    (state, action) => {
      let hasStateChanged = false;
      const newState: Record<string, unknown> = {};
      let nextStateForCurrentKey = {};
      for (let i = 0; i < reducerKeys.length; i++) {
        const currentKey = reducerKeys[i];
        const currentReducer = finalReducers[currentKey];
        const prevStateForCurrentKey = state[currentKey];
        nextStateForCurrentKey = currentReducer(prevStateForCurrentKey, action);
        hasStateChanged =
          hasStateChanged || nextStateForCurrentKey !== prevStateForCurrentKey;
        newState[currentKey] = nextStateForCurrentKey;
      }
      return hasStateChanged ? newState : state;
    },
    globalState as ReducerState<R>
  ] as [R, ReducerState<R>];
}

export const GlobalStateProvider: FC<GlobalStateProvicerProps> = ({
  children,
  cookies
}) => {
  const [reducer, initialState] = combineReducers({
    page: [pageStateReducer, null]
  });
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
