import { Box, BoxProps } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import { FC, Ref, useCallback, useMemo } from 'react';

export interface MotionBoxProps
  extends Omit<
      BoxProps,
      'style' | 'onAnimationStart' | 'onDrag' | 'onDragEnd' | 'onDragStart'
    >,
    MotionProps {
  ref?: Ref<HTMLElement>;
}

const MOTION_PROP_KEYS = [
  'ref',
  'initial',
  'animate',
  'exit',
  'transition',
  'variants',
  'style',
  'onAnimationStart',
  'onDrag',
  'onDragEnd',
  'onDragStart'
];

export const MotionBox: FC<MotionBoxProps> = ({
  component = 'div',
  ...props
}) => {
  const { motionProps, boxProps } = useMemo(() => {
    const motion: MotionProps = {};
    const box: BoxProps = {};

    for (const key of Object.keys(props)) {
      if (MOTION_PROP_KEYS.indexOf(key) > -1) {
        motion[key as keyof MotionProps] = props[key as keyof typeof props];
        continue;
      }
      box[key as keyof BoxProps] = props[key as keyof typeof props];
    }
    return { motionProps: motion, boxProps: box };
  }, [props]);
  const MotionComponent = motion?.[component as 'div'];

  const Component = useCallback(
    ({ sx: _sx, ...innerProps }) => {
      return MotionComponent ? (
        <MotionComponent {...motionProps} {...innerProps} />
      ) : null;
    },
    [MotionComponent]
  );

  if (!Component) {
    return null;
  }
  return <Box component={Component} {...boxProps} />;
};
