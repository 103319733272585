import { createContext, useContext, Dispatch } from 'react';
import { PageState } from 'cms/store/pageStore';

export interface Payload {
  [key: string]: unknown | unknown[] | undefined;
}

export interface GlobalStateAction {
  type: string;
  payload?: Payload;
}

export interface InitialGlobalState {
  page?: PageState;
}

export const GlobalStateContext = createContext<{
  state: InitialGlobalState;
  dispatch: Dispatch<GlobalStateAction>;
}>({
  state: {},
  dispatch: () => null
});

export const useGlobalState = () => useContext(GlobalStateContext);
