import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  split,
  NormalizedCacheObject,
  Operation,
  FetchResult
} from '@apollo/client';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { NextPageContext } from 'next';
import { parseCookies, destroyCookie, setCookie } from 'nookies';
import {
  AuthenticateDocument,
  AuthenticateMutation
} from 'authentication/mutations/authenticate';
import { AuthMethod } from 'common/types/graphql-types';
import merge from 'lodash.merge';
import flow from 'lodash.flow';
import {
  cacheOptions as AuthCache,
  AUTH_COOKIE_NAME,
  AUTH_ID
} from 'authentication/schema/schema';
import {
  cacheOptions as UserCache,
  initializeReactiveVariables as initUserVars
} from 'user/schema/schema';
import { initializeReactiveVariables as initPaymentVars } from 'payment/schema/schema';
import { cacheOptions as TrackCache } from 'track/schema/schema';
import { cacheOptions as CommonCache } from 'common/schema/schema';
import { cacheOptions as ProjectCache } from 'project/schema/schema';
import {
  AuthFragment,
  AuthFragmentDoc
} from 'authentication/fragments/authFragment';
import getConfig from 'next/config';
import { print } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';
import { IncomingMessage } from 'http';

export interface ExtendedRequest extends IncomingMessage {
  apolloClient?: ApolloClient<NormalizedCacheObject>;
}
export interface NextApolloPageContext {
  req: ExtendedRequest;
}
declare global {
  interface Window {
    apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
  }
}
class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    options.webSocketImpl =
      typeof window === 'undefined' ? require('ws') : undefined;
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (Array.isArray(err))
              // GraphQLError[]
              return sink.error(
                new Error(err.map(({ message }) => message).join(', '))
              );

            if (err instanceof CloseEvent)
              return sink.error(
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}` // reason will be available on clean closes only
                )
              );

            return sink.error(err);
          }
        }
      );
    });
  }
}

const { publicRuntimeConfig } = getConfig();

const httpLink = createHttpLink({
  uri:
    typeof window === 'undefined'
      ? publicRuntimeConfig.ssrGraphqlUri
      : publicRuntimeConfig.graphqlUri
});

const wsLink = new WebSocketLink({
  url: publicRuntimeConfig.graphqlSocketUri as string,
  lazy: true,
  connectionParams: () => {
    const authHeaders: HeadersInit = {};
    const client = getApolloClient();

    const auth = client.readFragment<AuthFragment>({
      id: AUTH_ID,
      fragment: AuthFragmentDoc
    });

    if (auth) {
      authHeaders.Authorization = `Bearer ${auth.token}`;
    }

    return authHeaders;
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const createErrorLink = (ctx?: NextApolloPageContext) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    const reporter = console;
    if (graphQLErrors) {
      const apolloClient = getApolloClient(null, ctx);
      for (const error of graphQLErrors) {
        switch (error?.extensions?.code) {
          case 'UNAUTHENTICATED': {
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver

            // modify the operation context with a new token
            const operationAuth = operation.getContext()?.cookie;

            if (!operationAuth?.user || !operationAuth?.refreshToken) {
              break;
            }

            return new Observable((observer) => {
              const cookieAuth = getAuthCookie(ctx);
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              };

              if (operationAuth.refreshToken !== cookieAuth?.refreshToken) {
                operation
                  .setContext({ ...operation.getContext(), cookie: cookieAuth })
                  .forward(operation)
                  .subscribe(subscriber);
                return;
              }

              apolloClient
                ?.mutate<AuthenticateMutation>({
                  mutation: AuthenticateDocument,
                  variables: {
                    identifier: cookieAuth?.user?.id,
                    secret: cookieAuth?.refreshToken,
                    authMethod: AuthMethod.RefreshToken
                  }
                })
                .then(({ data, errors }) => {
                  if (!data || errors?.length) {
                    throw errors || new Error('No auth data returned');
                  }

                  setCookie(
                    ctx as NextPageContext,
                    AUTH_COOKIE_NAME,
                    JSON.stringify(data.authenticate),
                    {
                      path: '/',
                      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
                    }
                  );
                  forward(operation).subscribe(subscriber);
                })
                .catch(() => {
                  destroyCookie(ctx as NextPageContext, AUTH_COOKIE_NAME, {
                    path: '/'
                  });

                  forward(operation).subscribe(subscriber);

                  if (typeof window !== 'undefined') {
                    window.location.href = '/login'
                  }
                }); 
            });
          }

          case 'BAD_USER_INPUT': {
            break;
          }
          default: {
            reporter.error(error);
            break;
          }
        }
      }
    }

    if (networkError) {
      reporter.error(networkError);
    }
  });

const getAuthCookie = (ctx?: NextApolloPageContext) => {
  const cookie = parseCookies(ctx)?.[AUTH_COOKIE_NAME];
  if (!cookie) {
    return;
  }
  return JSON.parse(cookie) as AuthFragment;
};

const getAuthorizationLink = (ctx?: NextApolloPageContext) =>
  setContext(() => {
    const cookie = getAuthCookie(ctx);
    if (!cookie) {
      return;
    }

    return {
      headers: { authorization: `Bearer ${cookie.token}` },
      cookie
    };
  });

const createApolloClient = (ctx?: NextApolloPageContext) => {
  const cache = new InMemoryCache(
    merge({}, AuthCache, CommonCache, ProjectCache, TrackCache, UserCache)
  );
  flow([initUserVars, initPaymentVars])();

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: ApolloLink.from([
      createErrorLink(ctx),
      getAuthorizationLink(ctx),
      splitLink
    ]),
    cache
  });
};

export const getApolloClient = (
  initialState: NormalizedCacheObject | null = null,
  ctx?: NextApolloPageContext
) => {
  if (
    ctx?.req?.apolloClient ||
    (typeof window !== 'undefined' && window?.apolloClient)
  ) {
    return (ctx?.req?.apolloClient ||
      window?.apolloClient) as ApolloClient<NormalizedCacheObject>;
  }
  const _apolloClient = createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  // if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (typeof ctx !== 'undefined') {
    ctx.req.apolloClient = _apolloClient;
  } else if (typeof window !== 'undefined') {
    window.apolloClient = _apolloClient;
  }
  return _apolloClient;
};

export const useApollo = (initialState: NormalizedCacheObject) => {
  const store = useMemo(() => getApolloClient(initialState), [initialState]);
  return store;
};
