import { createPlayerStore, DEFAULT_PLAYER_STATE, PlayerState, usePlayerStore } from "track/state/player";
import create from 'zustand'

type StateCb = (state: GlobalPlayerState) => void

export interface GlobalPlayerState extends PlayerState {
  track: string | null;
  onPrev: StateCb | null;
  onNext: StateCb | null;
  setTrack: (track: string | null) => void
  setOnPrev: (cb: StateCb | null) => void
  setOnNext: (cb: StateCb | null) => void
}

const DEFAULT_GLOBAL_PLATER_STATE = {
  ...DEFAULT_PLAYER_STATE,
  track: null
}

export const useGlobalPlayerStore = create<GlobalPlayerState>((set, get) => {
  const { reset: parentReset, setSource, ...parentState } = createPlayerStore<GlobalPlayerState>(set, get);
  let isSetup = false;

  const reset = () => {
    parentReset()
    setupHandlers()
    set(DEFAULT_GLOBAL_PLATER_STATE)
  }

  const setupHandlers = () => {
    if (typeof navigator === 'undefined' || !navigator?.mediaSession?.setActionHandler) {
      return
    }

    navigator.mediaSession.setActionHandler('previoustrack', () => {
      const state = get()
      if (!state.onPrev) {
        return;
      }

      state.onPrev(state);
    });

    navigator.mediaSession.setActionHandler('nexttrack', () => {
      const state = get()
      if (!state.onNext) {
        return;
      }

      state.onNext(state);
    });
  }

  return {
    track: null,
    onPrev: null,
    onNext: null,
    reset,
    setSource: (source: string | null) => {
      const state = get()
      if (state?.context && !isSetup) {
        setupHandlers()
        isSetup = true;
      }
      setSource(source)
    },
    setTrack: (track: string | null) => set({ track }),
    setOnPrev: (onPrev: StateCb | null) => set({ onPrev }),
    setOnNext: (onNext: StateCb | null) => set({ onNext }),
    ...parentState
  }
})