import { Slider, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { MotionBox } from 'common/components/MotionBox';
import { formatSeconds } from 'common/methods/formatSeconds';
import {
  GlobalPlayerState,
  useGlobalPlayerStore
} from 'project/state/globalPlayer';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { PlayerState, usePlayerStore } from 'track/state/player';

const duration = 0.3;
const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: duration }
  }
};

interface SliderPlayerProps {
  store?: 'globalplayer' | 'player';
  variant?: 'inline' | 'full';
}

export const SliderPlayer: FC<SliderPlayerProps> = ({
  store = 'player',
  variant = 'full'
}) => {
  const theme = useTheme();
  const useStore: <T extends unknown>(
    cb: (state: GlobalPlayerState | PlayerState) => T
  ) => T = useMemo(() => {
    switch (store) {
      case 'globalplayer':
        return useGlobalPlayerStore;
      default:
        return usePlayerStore;
    }
  }, [store]);

  const duration = useStore(useCallback((state) => state.duration, []));
  const currentTime = useStore(useCallback((state) => state.currentTime, []));
  const seek = useStore(useCallback((state) => state.seek, []));
  const isPlaying = useStore(useCallback((state) => state.isPlaying, []));
  const selection = useStore(useCallback((state) => state.selection, []));
  const [tempPlayhead, setTempPlayhead] = useState<number | null>(null);
  const [showSeek, setShowSeek] = useState(
    typeof window !== 'undefined' &&
      !window?.navigator?.userAgent.match(/iPad|iPhone/i)
  );

  const marks = useMemo(() => {
    if (!selection) {
      return undefined;
    }
    return [
      {
        value: selection.range[0] as number,
        label: formatSeconds(selection.range[0] as number)
      },
      {
        value: selection.range[1] as number,
        label: formatSeconds(selection.range[1] as number)
      }
    ];
  }, [selection]);

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
    setShowSeek(true);
  }, [isPlaying]);

  const handleOnChangeCommitted = useCallback(
    (_: Event | SyntheticEvent, value: number | number[]) => {
      if (Array.isArray(value)) {
        value = value[0];
      }
      seek(value);
      setTempPlayhead(null);
    },
    []
  );

  const handleChange = useCallback((_: Event, value: number | number[]) => {
    if (Array.isArray(value)) {
      value = value[0];
    }

    setTempPlayhead(value);
  }, []);

  const timeLeft = Math.round(duration - (tempPlayhead || currentTime || 0));

  return (
    <Box padding={variant === 'inline' ? 0 : 2} position='relative'>
      <Slider
        value={tempPlayhead || currentTime}
        min={0}
        max={duration}
        sx={{
          ...(variant === 'inline'
            ? {
                marginX: 6,
                zIndex: 1,
                width: `calc(100% - ${theme.spacing(12)})`
              }
            : {}),
          marginBottom: 0,
          '& .MuiSlider-mark': {
            backgroundColor: theme.palette.primary.main,
            height: theme.spacing(2),
            transform: 'translateY(-50%)'
          },
          '& .MuiSlider-valueLabel': {
            top: theme.spacing(-5)
          },
          '& .MuiSlider-markLabel': {
            color: theme.palette.text.secondary,
            fontSize: 12,
            transform: 'translateX(-50%)',
            top: theme.spacing(-2),
            '&~ .MuiSlider-markLabel': {
              transform: 'translateX(0)'
            }
          }
        }}
        marks={marks}
        onChange={handleChange}
        onChangeCommitted={handleOnChangeCommitted}
      />
      {showSeek && (
        <MotionBox
          variants={variants}
          initial='initial'
          animate='enter'
          exit='exit'
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            sx={{
              ...(variant === 'inline'
                ? {
                    position: 'absolute',
                    left: 0,
                    zIndex: 0,
                    top: theme.spacing(1),
                    width: '100%'
                  }
                : {})
            }}
          >
            <Typography variant={'overline'}>
              {formatSeconds(tempPlayhead || currentTime)}
            </Typography>
            <Typography variant={'overline'}>
              {timeLeft > 0 ? '-' : ''}
              {formatSeconds(timeLeft)}
            </Typography>
          </Box>
        </MotionBox>
      )}
    </Box>
  );
};
