import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseTrackFragmentDoc } from './baseTrack';
export type BaseRevisionFragment = { __typename?: 'Revision', id: string, name?: string | null | undefined, index: number, isPrimary: boolean, createdAt: number, isProcessed: boolean, status: Types.RevisionStatus, isLocked: boolean, isMine: boolean, canPlaythrough: boolean, canDownload: Types.CanDownload, canDelete: boolean, canLock: boolean, canUnlock: boolean, canSetPrimary: boolean, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined, wavFilename?: string | null | undefined } | null | undefined, settings?: { __typename?: 'RevisionSettings', colors: Types.RevisionColors, dynamics: Types.RevisionDynamics } | null | undefined, track: { __typename?: 'Track', id: string, index: number, title: string, hasPendingRevisions: boolean, canEdit: boolean, canDelete: boolean, updatedAt: number, status?: Types.RevisionStatus | null | undefined, primaryRevision?: { __typename?: 'Revision', id: string, canDownload: Types.CanDownload, isProcessed: boolean, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined } | null | undefined, album?: { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, canUpload: boolean } | null | undefined } };

export const BaseRevisionFragmentDoc = gql`
    fragment BaseRevision on Revision {
  id
  name
  index
  isPrimary
  createdAt
  master {
    mp3
    wav
  }
  original {
    mp3
    wav
    wavFilename
  }
  isProcessed
  createdAt
  status
  settings {
    colors
    dynamics
  }
  isLocked
  isMine
  canPlaythrough
  canDownload
  canDelete
  canLock
  canUnlock
  canSetPrimary
  track {
    ...BaseTrack
  }
}
    ${BaseTrackFragmentDoc}`;