import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { AuthFragmentDoc } from '../fragments/authFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthenticateMutationVariables = Types.Exact<{
  identifier: Types.Scalars['String'];
  secret: Types.Scalars['String'];
  authMethod?: Types.InputMaybe<Types.AuthMethod>;
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', authenticate: { __typename?: 'Auth', token: string, refreshToken: string, user: { __typename?: 'User', id: string, username: string, firstName: string, lastName: string, avatar?: string | null | undefined, roles: Array<Types.AuthRole>, affiliate?: string | null | undefined } } };


export const AuthenticateDocument = gql`
    mutation Authenticate($identifier: String!, $secret: String!, $authMethod: AuthMethod) {
  authenticate(
    input: {identifier: $identifier, secret: $secret, authMethod: $authMethod}
  ) {
    ...Auth
  }
}
    ${AuthFragmentDoc}`;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      secret: // value for 'secret'
 *      authMethod: // value for 'authMethod'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;