import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
export type BaseTrackFragment = { __typename?: 'Track', id: string, index: number, title: string, hasPendingRevisions: boolean, canEdit: boolean, canDelete: boolean, updatedAt: number, status?: Types.RevisionStatus | null | undefined, primaryRevision?: { __typename?: 'Revision', id: string, canDownload: Types.CanDownload, isProcessed: boolean, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined } | null | undefined, album?: { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, canUpload: boolean } | null | undefined };

export const BaseTrackFragmentDoc = gql`
    fragment BaseTrack on Track {
  id
  index
  title
  hasPendingRevisions
  canEdit
  primaryRevision {
    id
    canDownload
    isProcessed
    original {
      mp3
      wav
    }
    master {
      mp3
      wav
    }
  }
  canDelete
  updatedAt
  status
  album {
    id
    title
    artist
    artwork
    canUpload
  }
}
    `;