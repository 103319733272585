import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseProjectFragmentDoc } from '../fragments/baseProject';
import { BaseTrackFragmentDoc } from '../../track/fragments/baseTrack';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProjectQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  trackPage?: Types.InputMaybe<Types.Scalars['String']>;
  trackTerm?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetProjectQuery = { __typename?: 'Query', album: { __typename?: 'Album', isPublic: boolean, id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, updatedAt: number, trackCount: number, canInvite: boolean, canEdit: boolean, canUpload: boolean, canDelete: boolean, isDisabled: boolean, isMine: boolean, allowDownload: boolean, tracks: { __typename?: 'TrackPage', next?: string | null | undefined, results: Array<{ __typename?: 'Track', id: string, index: number, title: string, hasPendingRevisions: boolean, canEdit: boolean, canDelete: boolean, updatedAt: number, status?: Types.RevisionStatus | null | undefined, primaryRevision?: { __typename?: 'Revision', id: string, canDownload: Types.CanDownload, isProcessed: boolean, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined } | null | undefined, album?: { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, canUpload: boolean } | null | undefined }> }, myInvite?: { __typename?: 'ShareableMember', id: string, token?: string | null | undefined, isAccepted: boolean } | null | undefined, members?: Array<{ __typename?: 'ShareableMember', id: string, email?: string | null | undefined, canDelete: boolean, user?: { __typename?: 'User', id: string, avatar?: string | null | undefined, firstName: string, lastName: string, username: string } | null | undefined }> | null | undefined, creator: { __typename?: 'User', id: string, username: string, firstName: string, lastName: string } } };


export const GetProjectDocument = gql`
    query GetProject($id: UUID!, $trackPage: String, $trackTerm: String) {
  album(id: $id) {
    ...BaseProject
    tracks(input: {page: $trackPage, term: $trackTerm}) {
      next
      results {
        ...BaseTrack
      }
    }
    isPublic
    myInvite {
      id
      token
      isAccepted
    }
    members {
      id
      email
      canDelete
      user {
        id
        avatar
        firstName
        lastName
        username
      }
    }
  }
}
    ${BaseProjectFragmentDoc}
${BaseTrackFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      trackPage: // value for 'trackPage'
 *      trackTerm: // value for 'trackTerm'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;