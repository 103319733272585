import { InMemoryCacheConfig, makeVar, ReactiveVar } from '@apollo/client';
import {
  BaseNotificationFragment,
  BaseNotificationFragmentDoc
} from 'user/fragments/baseNotification';
import {
  BaseRevisionFragment
} from 'track/fragments/baseRevision'
import { BaseUserFragment } from 'user/fragments/baseUser';
import { RevisionStatus } from 'common/types/graphql-types';
import { BaseProjectFragment } from 'project/fragments/baseProject';

export let markAsSeenIds: ReactiveVar<string[]>;

export const initializeReactiveVariables = () => {
  markAsSeenIds = makeVar([] as string[]);
};

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        user(_, { args, toReference }) {
          return toReference({
            __typename: 'User',
            id: args!.id
          });
        },
        users: {
          keyArgs: ['input', ['term']],
          merge(existing, incoming, { readField }) {
            const results: BaseUserFragment[] = [];
            const uniqueObjectsSet = new Set();
            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.__ref)) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.__ref);
            }

            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results
            };
          }
        }
      }
    },
    Mutation: {
      fields: {
        markAsSeen: {
          keyArgs: false,
          merge(existing, incoming, { args, cache }) {
            if (!args?.ids) {
              return incoming;
            }
            for (const id of args?.ids) {
              const exist = cache.readFragment<BaseNotificationFragment>({
                id: `Notification:${id}`,
                fragmentName: 'BaseNotification',
                fragment: BaseNotificationFragmentDoc
              })
              cache.writeFragment({
                id: `Notification:${id}`,
                fragmentName: 'BaseNotification',
                fragment: BaseNotificationFragmentDoc,
                data: {
                  ...exist,
                  seen: true
                }
              });
            }
          }
        }
      }
    },
    User: {
      fields: {
        pendingRevisions: {
          keyArgs: false,
          merge(existing, incoming, { readField }) {
            const results: BaseRevisionFragment[] = [];
            const uniqueObjectsSet = new Set();
            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.__ref) && !readField('isProcessed', object) && readField('status', object) !== RevisionStatus.Invalid) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.__ref);
            }

            const sorted = results.sort((a, b) => {
              return (readField('createdAt', a) || 0) <
                (readField('createdAt', b) || 0)
                ? 1
                : -1;
            });
            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results: sorted
            };
          }
        },
        notifications: {
          keyArgs: false,
          merge(existing, incoming, { readField }) {
            const results: BaseNotificationFragment[] = [];
            const uniqueObjectsSet = new Set();
            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.__ref)) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.__ref);
            }

            const sorted = results.sort((a, b) => {
              return (readField('createdAt', a) || 0) <
                (readField('createdAt', b) || 0)
                ? 1
                : -1;
            });
            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results: sorted
            };
          }
        },
        albums: {
          keyArgs: ['input', ['term', 'filter', 'sort']],
          merge(existing, incoming) {
            const results: { album: BaseProjectFragment }[] = [];
            const uniqueObjectsSet = new Set();
            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.album.__ref)) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.album.__ref);
            }

            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results
            };
          }
        }
      }
    }
  }
};
