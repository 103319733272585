import { createTheme, alpha } from '@mui/material/styles';
import {
  Theme,
  ThemeProvider as MuiThemeProvider,
  InputBaseProps,
  chipClasses,
  filledInputClasses,
  inputLabelClasses,
  FilledInputProps,
  ChipProps,
  svgIconClasses
} from '@mui/material';
import React, { FC } from 'react';
import type {} from '@mui/lab/themeAugmentation';
import { imageLoader } from '../methods/imageLoader';
import { Breakpoint, CSSObject } from '@mui/system';
import { CSSInterpolation } from '@emotion/serialize';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    makeBackground: (
      url?: string | null,
      quality?: number,
      includeContent?: boolean
    ) => CSSObject;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    transparent: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    default: true;
    white: true;
  }
  interface FabPropsSizeOverrides {
    xsmall: true;
  }
}

declare module '@mui/material/SvgIcon/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xsmall: true;
  }
}

const propVariantMapper = <
  T extends string | number | undefined,
  P extends object = {}
>(
  propName: string,
  propValues: NonNullable<T>[],
  styleCB: (prop: NonNullable<T>) => CSSInterpolation,
  staticProps?: P
) =>
  propValues.map((prop) => ({
    props: { [propName]: prop, ...staticProps },
    style: styleCB(prop)
  }));

const baseTheme = createTheme({
  typography: {
    fontFamily: 'Raleway',
    h1: {
      fontWeight: 700,
      fontSize: 66,
      lineHeight: 1.17,
      letterSpacing: 0.5
    },
    h2: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 1.093,
      letterSpacing: 0.15
    },
    h3: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 1.035,
      letterSpacing: 0.15
    },
    h4: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.125,
      letterSpacing: 0.15
    },
    h5: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.15,
      letterSpacing: 0.15
    },
    h6: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 1.11,
      letterSpacing: 0.15
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1.23,
      letterSpacing: 0.25
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 13,
      lineHeight: 1.23,
      letterSpacing: 0.25
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: '3%'
    },
    body2: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: '3%'
    },
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.25,
      letterSpacing: 0.5
    },
    caption: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.07,
      letterSpacing: 0.5
    },
    overline: {
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 1.25,
      letterSpacing: 0.25
    }
  },
  palette: {
    mode: 'dark',
    contrastThreshold: 3,
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.75)',
      disabled: 'rgba(255, 255, 255, 0.6)'
    },
    background: {
      default: '#47404E',
      paper: '#372F3F'
    },
    divider: '#545166',
    primary: {
      light: '#A95EE3',
      main: '#9854CD',
      dark: '#853EBD'
    },
    secondary: {
      light: '#72B0BD',
      main: '#57A5B6',
      dark: '#348B9E',
      contrastText: '#FFFFFF'
    },
    info: {
      light: '#7D67D7',
      main: '#6D58C0',
      dark: '#5A45AF'
    },
    success: {
      light: '#14CA7E',
      main: '#04B86C',
      dark: '#04A15F'
    },
    warning: {
      light: '#F8CE53',
      main: '#FFC107',
      dark: '#CB7F00',
      contrastText: '#FFFFFF'
    },
    error: {
      light: '#FF7A7C',
      main: '#FF4D4D',
      dark: '#C62828'
    }
  }
});

const autoFillStyles = {
  WebkitBoxShadow: `0 0 0px 1000px ${baseTheme.palette.background.default} inset !important`,
  backgroundColor: `${baseTheme.palette.background.default} !important`,
  WebkitTextFillColor: `${baseTheme.palette.text.primary} !important`,
  color: `${baseTheme.palette.text.primary} !important`,
  border: `1px solid ${baseTheme.palette.info.main} !important`,
  caretColor: `${baseTheme.palette.text.primary} !important`,
  borderRadius: 'inherit',
  backgroundClip: 'content-box !important'
};

export const theme = createTheme({
  ...baseTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          paddingTop: 0,
          overflowX: 'hidden',
          overflowY: 'auto',
          zIndex: -1,
          overscrollBehaviorY: 'none',
          scrollbarColor: `${baseTheme.palette.divider} rgba(0,0,0,0)`,
          scrollbarWidth: 'thin',
          background:
            'linear-gradient(169.91deg, #22222B 0.92%, #281A33 111.41%) !important',
          backgroundColor: '#22222B !important'
        },
        html: {
          background:
            'linear-gradient(169.91deg, #22222B 0.92%, #281A33 111.41%) !important',
          backgroundColor: '#22222B !important'
        },
        '*::-webkit-scrollbar': {
          display: 'initial',
          width: baseTheme.spacing(0.5),
          height: baseTheme.spacing(1)
        },
        '*::-webkit-scrollbar-track': {
          borderWidth: 0,
          background: 'transparent'
        },
        '*::-webkit-scrollbar-button': { display: 'none' },
        '*::-webkit-scrollbar-track-piece': { display: 'none' },
        '*::-webkit-scrollbar-corner': { display: 'none' },
        '*::-webkit-resizer': { display: 'none' },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: baseTheme.spacing(1),
          backgroundColor: baseTheme.palette.divider
        },
        '.DraftEditor-root': {
          position: 'relative',
          height: '100%',
          width: '100%'
        },
        '.public-DraftEditorPlaceholder-root': {
          opacity: 0.5,
          position: 'absolute',
          zIndex: 1
        },
        '.DraftEditor-editorContainer': {
          height: '100%',
          overflowY: 'scroll',
          zIndex: 1
        },
        '#__next': {
          minHeight: '100vh'
        },
        '.rc-anchor-container': {
          border: 'none !important'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      },
      variants: [
        {
          props: { color: 'inherit' },
          style: {
            backgroundColor: '#382D49'
          }
        },
        {
          props: { color: 'default' },
          style: {
            backgroundColor: '#524D6F'
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '9.5px 12px !important',

          [`&.${filledInputClasses.focused}, &:hover:not(.${filledInputClasses.focused})`]:
            {
              padding: '7.5px 10px !important'
            }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: baseTheme.palette.background.default,
          color: baseTheme.palette.text.primary
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          border: `1px solid ${baseTheme.palette.background.paper}`,
          marginLeft: baseTheme.spacing(-4)
        }
      }
    },
    MuiBackdrop: {
      variants: [
        {
          props: { invisible: false },
          style: {
            backdropFilter: 'blur(4px)',
            transitionProperty: 'all !important'
          }
        }
      ]
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
        color: 'inherit'
      },
      variants: [
        {
          props: { color: 'inherit' },
          style: {
            borderColor: `${alpha(
              baseTheme.palette.common.white,
              0.23
            )} !important`
          }
        }
      ],
      styleOverrides: {
        containedPrimary: {
          '&:active': {
            backgroundColor: baseTheme.palette.primary.dark
          },
          '&:hover': {
            backgroundColor: baseTheme.palette.primary.light
          }
        },
        containedSecondary: {
          '&:active': {
            backgroundColor: baseTheme.palette.secondary.dark
          },
          '&:hover': {
            backgroundColor: baseTheme.palette.secondary.light
          }
        },
        outlined: {
          color: baseTheme.palette.common.white
        },
        root: {
          height: baseTheme.spacing(5),
          minWidth: baseTheme.spacing(15)
        },
        sizeSmall: {
          height: baseTheme.spacing(4),
          minWidth: 0
        },
        sizeLarge: {
          height: baseTheme.spacing(6)
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: (baseTheme.shape.borderRadius as number) * 2,
          backgroundColor: '#382D49'
        }
      }
    },
    MuiChip: {
      variants: [
        ...propVariantMapper<ChipProps['color']>(
          'color',
          ['primary', 'secondary', 'info', 'success', 'warning', 'error'],
          (color) => {
            if (color === 'default') {
              return undefined;
            }
            return {
              fill: baseTheme.palette?.[color].light,
              color: baseTheme.palette?.[color].light,
              backgroundColor: alpha(baseTheme.palette?.[color].dark, 0.2)
            };
          }
        ),
        {
          props: {
            color: 'default'
          },
          style: {
            fill: baseTheme.palette.text.primary,
            color: baseTheme.palette.text.secondary,
            backgroundColor: baseTheme.palette.background.paper
          }
        }
      ],
      styleOverrides: {
        deleteIcon: {
          fill: 'inherit'
        },
        icon: {
          fill: 'inherit'
        },
        root: {
          [`&.${chipClasses.disabled}`]: {
            opacity: 1
          }
        }
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 20,
        color: 'inherit'
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          height: '100%'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          overflowX: 'hidden'
        }
      }
    },
    MuiFab: {
      defaultProps: {
        color: 'default'
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            color: baseTheme.palette.common.white,
            backgroundColor: baseTheme.palette.background.default
          }
        },
        {
          props: { color: 'white' },
          style: {
            color: baseTheme.palette.action.active,
            backgroundColor: baseTheme.palette.common.white
          }
        },
        {
          props: { size: 'xsmall' },
          style: {
            height: baseTheme.spacing(3),
            width: baseTheme.spacing(3)
          }
        }
      ],
      styleOverrides: {
        root: {
          minHeight: 0,
          width: baseTheme.spacing(10),
          height: baseTheme.spacing(10)
        },
        colorInherit: {
          backgroundColor: alpha('#C4C4C4', 0.2)
        },
        sizeSmall: {
          minHeight: 0,
          width: baseTheme.spacing(4),
          height: baseTheme.spacing(4)
        },
        sizeMedium: {
          width: baseTheme.spacing(7),
          height: baseTheme.spacing(7)
        }
      }
    },
    MuiFilledInput: {
      variants: propVariantMapper<FilledInputProps['color']>(
        'color',
        ['primary', 'secondary', 'info', 'success', 'warning', 'error'],
        (color) => ({
          borderColor: baseTheme.palette?.[color || 'primary'].main
        })
      ),
      styleOverrides: {
        multiline: {
          alignItems: 'flex-start',
          padding: '16.5px 12px',
          [`& .${filledInputClasses.input}`]: {
            padding: 0
          },
          [`&.${filledInputClasses.focused}, &:hover:not(.${filledInputClasses.focused})`]:
            {
              padding: '14.5px 10px'
            }
        },
        adornedStart: {
          paddingLeft: '12px',
          [`&.${filledInputClasses.focused}, &:hover:not(.${filledInputClasses.focused})`]:
            {
              [`& .${svgIconClasses.root}`]: {
                marginRight: 2
              },
              paddingLeft: 10
            }
        },
        inputAdornedStart: {
          paddingLeft: 'inherit',
          [`&.${filledInputClasses.focused}, &:hover:not(.${filledInputClasses.focused})`]:
            {
              paddingLeft: 10
            }
        },
        root: {
          backgroundColor: baseTheme.palette.background.default,
          alignItems: 'center',
          boxSizing: 'border-box',
          borderRadius: 4,
          padding: 2,
          paddingRight: 4,
          borderWidth: 2,
          [`&.${filledInputClasses.focused}, &:hover:not(.${filledInputClasses.focused})`]:
            {
              padding: 0,
              paddingRight: 2,
              borderStyle: 'solid',
              backgroundColor: `${baseTheme.palette.background.default} !important`
            },
          [`&:hover:not(.${filledInputClasses.focused})`]: {
            border: '2px solid white'
          }
        },
        underline: {
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          }
        },
        input: {
          '&:autofill': autoFillStyles,
          '&:-webkit-autofill': autoFillStyles,
          '&:-webkit-autofill:hover': autoFillStyles,
          '&:-webkit-autofill:focus': autoFillStyles,
          '&:-webkit-autofill:active': autoFillStyles,
          padding: '14.5px 12px'
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 6
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&+div > div': {
            padding: '26px 17px 12px 24px'
          }
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 50
        }
      }
    },
    MuiInputBase: {
      variants: [
        ...propVariantMapper<InputBaseProps['rows']>(
          'rows',
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          (rows) => ({
            height: baseTheme.spacing(7 * parseInt(rows.toString(), 10))
          })
        ),
        {
          props: { multiline: true },
          style: {
            alignItems: 'flex-start',
            transition: baseTheme.transitions.create(['height'])
          }
        }
      ]
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          '&+div > input': {
            padding: '26px 17px 12px 24px'
          },
          '&+div > textarea': {
            marginTop: baseTheme.spacing(1)
          },
          [`&.${inputLabelClasses.root}`]: {
            [`&.${inputLabelClasses.focused}`]: {
              color: 'inherit'
            }
          },
          [`&.${inputLabelClasses.shrink}`]: {
            transform: `translate(24px, 10px) scale(0.75)`
          },
          transform: `translate(24px, 22.5px) scale(1)`
        }
      }
    },
    MuiLinearProgress: {
      variants: [
        {
          props: { variant: 'determinate' },
          style: {
            '&:after': {
              content: '""',
              position: 'absolute',
              height: '100%',
              opacity: 0.25,
              backgroundColor: 'currentColor',
              animation: 'indeterminate_second 1.5s infinite ease-out'
            },
            '@keyframes indeterminate_second': {
              '0%': {
                left: '-150%',
                width: '100%'
              },
              '100%': {
                left: '100%',
                width: '10%'
              }
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: 4
        },
        bar: {
          borderRadius: 4
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputAdornedStart: {
          paddingLeft: 'inherit'
        },
        root: {
          alignItems: 'center'
        },
        input: {
          padding: '8px 14px'
        }
      }
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            backgroundColor: `rgba(43, 33, 51, 0.88)`
          }
        }
      ],
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          width: baseTheme.spacing(45)
        },
        anchorOriginTopCenter: {
          top: `${baseTheme.spacing(11)} !important`
        },
        anchorOriginTopLeft: {
          top: `${baseTheme.spacing(11)} !important`
        },
        anchorOriginTopRight: {
          top: `${baseTheme.spacing(11)} !important`
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'xsmall' },
          style: {
            fontSize: 14
          }
        }
      ]
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          '&:after': {
            backgroundColor: baseTheme.palette.background.default,
            bottom: 0,
            content: '""',
            display: 'none',
            height: 4,
            justifyContent: 'center',
            position: 'absolute',
            width: 'calc(100% - 16px)'
          },
          fontSize: 16,
          fontWeight: 700,
          minWidth: baseTheme.spacing(15),
          position: 'relative'
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
        textColor: 'inherit',
        TabIndicatorProps: { children: <span /> }
      },
      styleOverrides: {
        indicator: {
          '& span': {
            backgroundColor: baseTheme.palette.primary.main,
            height: 4,
            width: '100%'
          },
          backgroundColor: 'transparent',
          display: 'flex',
          height: 4,
          justifyContent: 'center'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          height: baseTheme.spacing(9),
          justifyContent: 'space-between'
        }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: baseTheme.palette.common.black
        }
      }
    }
  },
  mixins: {
    makeBackground: (
      src?: string | null,
      quality = 75,
      includeContent = false
    ) => {
      if (!src) {
        return {};
      }

      const makeBackgroundUrl = (width: number) =>
        `url(${imageLoader({ src, width, quality })})`;
      const valueMap = {
        xs: 640,
        sm: 828,
        md: 1200,
        lg: 1920,
        xl: 3840
      };
      return Object.keys(theme.breakpoints.values).reduce<{
        [key: string]: CSSObject;
      }>((acc, key) => {
        const image = makeBackgroundUrl(valueMap[key as keyof typeof valueMap]);
        acc[theme.breakpoints.only(key as Breakpoint)] = {
          backgroundImage: !includeContent ? image : undefined,
          content: includeContent ? image : undefined,
          '&:after': {
            opacity: 0,
            content: image
          }
        };
        return acc;
      }, {});
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536
    }
  }
});

export const ThemeProvider: FC = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
