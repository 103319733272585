import { ImageLoader } from 'next/image';
import createHmac from 'create-hmac';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const urlSafeBase64 = (str: Buffer | string) => {
  return Buffer.from(str)
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

const hexDecode = (hex: string) => Buffer.from(hex, 'hex');

const sign = (target: string) => {
  const hmac = createHmac('sha256', hexDecode(publicRuntimeConfig.cdnKey));
  hmac.update(hexDecode(publicRuntimeConfig.cdnSalt));
  hmac.update(target);
  return urlSafeBase64(hmac.digest());
};

export const imageLoader: ImageLoader = ({ src, width, quality = 80 }) => {
  const encoded_url = urlSafeBase64(src);
  const path = `/rt:fit/w:${width}/q:${quality}/${encoded_url}`;

  const signature = sign(path);
  return `${publicRuntimeConfig.cdnUri}/${signature}${path}`;
};