import create, { SetState, GetState, UseBoundStore } from 'zustand';
import {
  StoreApiWithSubscribeWithSelector,
  subscribeWithSelector
} from 'zustand/middleware';
import throttle from 'lodash/throttle';

export interface Selection {
  editable?: boolean;
  range: [number, number];
}

type StateCb = (state: PlayerState) => void;

export interface PlayerState {
  source: string | null;
  context: AudioContext | null;
  isPlaying: boolean;
  currentTime: number;
  duration: number;
  selection: Selection | null;
  onLoaded: StateCb | null;
  onError: StateCb | null;
  onEnded: StateCb | null;

  play: () => void;
  pause: () => void;
  setOnError: (cb: StateCb) => void;
  setOnEnded: (cb: StateCb) => void;
  seek: (currentTime: number) => void;
  setSource: (source: string | null) => void;
  makeSelection: (selection: Selection | null) => void;
  reset: () => void;
}

export const DEFAULT_PLAYER_STATE = {
  source: null,
  isPlaying: false,
  currentTime: 0,
  duration: 0,
  selection: null,
  onLoaded: null,
  onEnded: null,
  onError: null
};

export const createPlayerStore = <T extends PlayerState>(
  set: SetState<T>,
  get: GetState<T>
) => {
  let context: AudioContext | null = null;
  let audio: HTMLAudioElement | null = null;

  const setupHandlers = () => {
    if (!audio) {
      return;
    }
    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('loadeddata', onLoaded);
    audio.addEventListener('ended', onEnded);
    audio.addEventListener('error', onError);
    audio.addEventListener('play', updateCurrentTime);
    if (
      typeof navigator === 'undefined' ||
      !navigator?.mediaSession?.setActionHandler
    ) {
      return;
    }

    navigator.mediaSession.setActionHandler('play', async () => {
      await play();
    });
    navigator.mediaSession.setActionHandler('pause', () => {
      pause();
    });
    navigator.mediaSession.setActionHandler('seekto', (evt) => {
      if (!evt.seekTime) {
        return;
      }
      seek(evt.seekTime);
    });
  };

  const setupContext = () => {
    //@ts-ignore webkitAudioContext is required for older safari versions
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext();
    audio = new Audio();
    setupHandlers();
  };

  const play = async () => {
    set({ isPlaying: true });
    context?.resume();
    requestAnimationFrame(updateCurrentTime);
    await audio?.play();
  };

  const pause = () => {
    set({ isPlaying: false });
    audio?.pause();
  };

  const updateCurrentTime = throttle(() => {
    if (!audio) {
      return;
    }
    const currentTime =
      Math.floor((audio.currentTime + Number.EPSILON) * 100) / 100;

    // TODO: Set up loop selection toggle
    // if (state?.selection && state?.selection?.range[1] < currentTime) {
    //   seek(state?.selection?.range[0])
    // }
    if (currentTime === 0 || currentTime >= get().currentTime) {
      set({ currentTime });
    }
    if (!audio.paused) {
      requestAnimationFrame(updateCurrentTime);
    }
  }, 25);

  const seek = (currentTime: number) => {
    set({ currentTime });
    if (!audio) {
      return;
    }
    audio.currentTime = currentTime;
  };

  const onEnded = () => {
    const state = get();
    const cb = state.onEnded;
    set({ isPlaying: false });
    if (cb) {
      cb(state);
    }
  };

  const onError = () => {
    const state = get();
    const cb = state.onError;
    if (cb) {
      cb(state);
    }
  };

  const onLoaded = async () => {
    const state = get();
    if (!audio) {
      return;
    }

    audio.currentTime = state.currentTime;
    if (state.isPlaying && audio.paused) {
      await play();
    }
    setupHandlers();

    if (state.onLoaded) {
      state.onLoaded(state);
    }
  };
  const onLoadedMetadata = () => audio && set({ duration: audio.duration });

  if (typeof window !== 'undefined') {
    setupContext();
  }

  return {
    context,
    ...DEFAULT_PLAYER_STATE,
    play,
    pause,
    setOnLoaded: (cb: StateCb) => set({ onLoaded: cb }),
    setOnEnded: (cb: StateCb) => set({ onEnded: cb }),
    setOnError: (cb: StateCb) => set({ onError: cb }),
    seek,
    setSource: (source: string | null) => {
      set({ source });
      if (!audio || !source) {
        return;
      }

      if (audio?.src === source) {
        return;
      }

      const state = get();
      audio.src = source;
      audio.load();
      audio.muted = false;
      audio.currentTime = state.currentTime;
    },
    makeSelection: (selection: Selection | null) => {
      set({ selection });
      if (!selection) {
        return;
      }
      seek(selection?.range?.[0]);
    },
    reset: () => {
      set(DEFAULT_PLAYER_STATE);
      audio?.pause();
      audio?.remove();
      setupContext();
    }
  };
};

export const usePlayerStore = create<PlayerState>(
  subscribeWithSelector(createPlayerStore)
) as UseBoundStore<PlayerState, StoreApiWithSubscribeWithSelector<PlayerState>>;
