import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
export type AuthFragment = { __typename?: 'Auth', token: string, refreshToken: string, user: { __typename?: 'User', id: string, username: string, firstName: string, lastName: string, avatar?: string | null | undefined, roles: Array<Types.AuthRole>, affiliate?: string | null | undefined } };

export const AuthFragmentDoc = gql`
    fragment Auth on Auth {
  token
  refreshToken
  user {
    id
    username
    firstName
    lastName
    avatar
    roles
    affiliate
  }
}
    `;