import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
export type BaseProjectFragment = { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, updatedAt: number, trackCount: number, canInvite: boolean, canEdit: boolean, canUpload: boolean, canDelete: boolean, isDisabled: boolean, isMine: boolean, allowDownload: boolean, isPublic: boolean, creator: { __typename?: 'User', id: string, username: string, firstName: string, lastName: string } };

export const BaseProjectFragmentDoc = gql`
    fragment BaseProject on Album {
  id
  title
  artist
  artwork
  updatedAt
  trackCount
  canInvite
  canEdit
  canUpload
  canDelete
  isDisabled
  isMine
  allowDownload
  isPublic
  creator {
    id
    username
    firstName
    lastName
  }
}
    `;