import { InMemoryCacheConfig } from '@apollo/client';
import { BaseProjectFragment } from 'project/fragments/baseProject';
import { GetProjectDocument, GetProjectQuery } from 'project/queries/getProject';
import { BaseTrackFragment } from 'track/fragments/baseTrack';
import { MeDocument, MeQuery } from 'user/queries/me';

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Mutation: {
      fields: {
        deleteAlbum: {
          merge(existing, incoming, { args, cache }) {
            const albumId = cache.identify({ __typename: 'Album', id: args!.id })
            const album = cache.readQuery<GetProjectQuery>({
              id: albumId,
              query: GetProjectDocument
            });
            const meQeury = cache.readQuery<MeQuery>({
              query: MeDocument
            })
            const tracks: string[] = album?.album?.tracks?.results?.map(({ id }) => id) || [];

            for (const revision of meQeury?.me?.pendingRevisions?.results || []) {
              if (!tracks.includes(revision.track?.id)) {
                continue
              }
              cache.evict({
                id: cache.identify({ __typename: 'Revision', id: revision.id })
              });
            }

            cache.evict({
              id: albumId
            });
            return incoming;
          }
        },
      }
    },
    Query: {
      fields: {
        album(_, { args, toReference }) {
          return toReference({
            __typename: 'Album',
            id: args!.id
          });
        },
        albums: {
          keyArgs: ['input', ['term', 'filter', 'sort']],
          merge(existing, incoming) {
            const results: { album: BaseProjectFragment }[] = [];
            const uniqueObjectsSet = new Set();
            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.album.__ref)) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.album.__ref);
            }

            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results
            };
          }
        }
      }
    },
    Album: {
      fields: {
        tracks: {
          keyArgs: ['input', ['term']],
          merge(existing, incoming) {
            const results: BaseTrackFragment[] = [];
            const uniqueObjectsSet = new Set();

            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.__ref)) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.__ref);
            }

            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results
            };
          }
        }
      }
    },
  }
};
