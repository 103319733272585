import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProjectMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  title?: Types.InputMaybe<Types.Scalars['String']>;
  artist?: Types.InputMaybe<Types.Scalars['String']>;
  artwork?: Types.InputMaybe<Types.Scalars['String']>;
  allowDownload?: Types.InputMaybe<Types.Scalars['Boolean']>;
  isPublic?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateAlbum: { __typename?: 'AlbumMutationResponse', album: { __typename?: 'Album', id: string, artwork?: string | null | undefined, artist?: string | null | undefined, title: string } } };


export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: UUID!, $title: String, $artist: String, $artwork: String, $allowDownload: Boolean, $isPublic: Boolean) {
  updateAlbum(
    input: {id: $id, title: $title, artist: $artist, artwork: $artwork, allowDownload: $allowDownload, isPublic: $isPublic}
  ) {
    album {
      id
      artwork
      artist
      title
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      artist: // value for 'artist'
 *      artwork: // value for 'artwork'
 *      allowDownload: // value for 'allowDownload'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;