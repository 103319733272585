import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseUserFragmentDoc } from './baseUser';
export type BaseNotificationFragment = { __typename?: 'Notification', id: string, type: Types.NotificationType, seen: boolean, createdAt: number, from?: { __typename?: 'User', id: string, avatar?: string | null | undefined, firstName: string, lastName: string, username: string } | null | undefined, comment?: { __typename?: 'Comment', id: string, parent?: { __typename?: 'Comment', text: string } | null | undefined } | null | undefined, revision?: { __typename?: 'Revision', id: string, name?: string | null | undefined, index: number } | null | undefined, track?: { __typename?: 'Track', id: string, title: string } | null | undefined, album?: { __typename?: 'Album', id: string, title: string } | null | undefined };

export const BaseNotificationFragmentDoc = gql`
    fragment BaseNotification on Notification {
  id
  type
  seen
  createdAt
  from {
    ...BaseUser
  }
  comment {
    id
    parent {
      text
    }
  }
  revision {
    id
    name
    index
  }
  track {
    id
    title
  }
  album {
    id
    title
  }
}
    ${BaseUserFragmentDoc}`;