export const TOGGLE_PROJECT_VIEW = 'Toggle Project View';
export const TOGGLE_SHOW_CREATE_PROJECT_MODAL = 'Toggle Show Create Project Modal';
export const TOGGLE_SHOW_PROJECT_OVERFLOW_MENU = 'Toggle Show Project Overflow Menu';
export const TOGGLE_SHOW_PROJECT_OVERFLOW_MODAL = 'Toggle Show Project Overflow Modal';
export const PROJECT_FILE_DROP = 'Project File Drop';
export const PROJECT_CREATED = 'Project Created';
export const PROJECT_UPDATED = 'Project Updated';
export const PROJECT_DELETED = 'Project Deleted';
export const COLLABORATORS_ADDED = 'Collaborators Added';
export const COLLABORATOR_REMOVED = 'Collaborator Removed';
export const SEARCH_PROJECTS = 'Search Projects';
export const LOAD_MORE_PROJECTS = 'Load More Projects';
export const TOGGLE_REORDER_TRACKS_VIEW = 'Toggle Reorder Tracks View';
export const SAVE_TRACK_ORDER = 'Save Track Order';
export const TOGGLE_PREVIEW_TRACKS_MODAL = 'Toggle Preview Tracks Modal';
export const TOGGLE_PREVIEW_TRACKS_PLAYING = 'Toggle Preview Tracks Playing';
export const PREVIEW_TRACKS_PREV = 'Preview Tracks Prev';
export const PREVIEW_TRACKS_NEXT = 'Preview Tracks Next';