import { wrap, proxy } from 'comlink';
import { getApolloClient } from 'common/hooks/useApollo';
import { CreateFileUploadDocument, CreateFileUploadMutation, CreateFileUploadMutationVariables } from 'common/mutations/createFileUpload';
import { FileUploadPurpose } from 'common/types/graphql-types';
import { WorkerApi } from 'common/workers/uploads/regular.worker';
import { v4 as uuid } from 'uuid';
import create from 'zustand';
import { useSnackbarsStore } from './snackbars';

export interface Upload {
  id: string;
  progress: number;
  filename: string;
  gsUri: string | null;
  purpose: FileUploadPurpose;
  error: string | null;
}

export interface UploadFileOptions {
  file: File,
  purpose: FileUploadPurpose;
  onComplete?: (upload: Upload) => void;
  onError?: (upload?: Upload) => void
}

export interface UploadsState {
  uploads: Record<string, Upload>;
  uploadFile: (options: UploadFileOptions) => Promise<string>;
}

export const useUploadsStore = create<UploadsState>((set, get) => {
  const getWorker = () => {
    const worker = new Worker(
      new URL(
        'common/workers/uploads/regular.worker',
        import.meta.url
      ),
      {
        type: 'module'
      }
    );
    return worker;
  }

  const updateUpload = ({ id, ...upload }: Partial<Upload> & Pick<Upload, 'id'>) => {
    const uploads = get().uploads;
    const existing = uploads?.[id] || {}

    if (upload.error) {
      const snackbarStore = useSnackbarsStore.getState()
      snackbarStore.addSnackbar({ severity: 'error', text: upload.error })
      removeUpload(id)
      return;
    }
    set({ uploads: { ...uploads, [id]: { ...existing, ...upload } } });
  }

  const createUpload = (upload: Omit<Upload, 'progress' | 'gsUri' | 'error'>) => updateUpload({ ...upload, progress: 0, gsUri: null, error: null })

  const removeUpload = (id: string) => {
    const uploads = { ...get().uploads };
    delete uploads?.[id]
    set({ uploads });
  }

  const createHandleProgress = (id: string) => (progress: number | null) => {
    updateUpload({ id, progress: progress || 0 });
  }

  const createOnComplete = (id: string, worker: Worker, cb?: Function) => () => {
    const upload = get().uploads?.[id];

    if (cb) {
      cb(upload);
    }
    removeUpload(id);
    worker.terminate()
  }

  const createHandleError = (id: string, cb?: Function) => () => {
    updateUpload({ id, error: 'There was a problem uploading your file. Please try again.' })

    if (cb) {
      const upload = get().uploads?.[id];
      cb(upload);
    }
  }

  const validateFile = (id: string, file: File, purpose: FileUploadPurpose) => {
    switch (purpose) {
      case FileUploadPurpose.ProjectArtwork:
      case FileUploadPurpose.Avatar:
        const img = new window.Image();
        img.onload = () => {
          const filesize = file.size / 1024 / 1024;
          const megapixels = (img.height * img.width) / 1024 / 1024;
          if (megapixels > 16.8) {
            return 'Image is over maximum resolution of 4000 x 4000 pixels.'
          }
          if (filesize > 10) {
            return 'Image is larger than maximum size of 10mb.'
          }
          return null
        };
        img.src = URL.createObjectURL(file);
      case FileUploadPurpose.Content:
        return null;
      case FileUploadPurpose.Track:
        const [, ext] = file?.name?.split(/\.(?=[^\.]+$)/);
        if (ext.toLocaleLowerCase() !== 'wav' || !/audio\/(?:x\-)?wav$/.test(file.type)) {
          return `${file?.name} error: Audio files must be in .wav format.`
        }
        return null
    }
  }

  const uploadFile = async ({ file, purpose, onComplete: onCompleteCb, onError: onErrorCb }: UploadFileOptions) => {
    const apollo = getApolloClient({})
    const worker = getWorker()
    const workerApi = wrap<WorkerApi>(worker)
    const id = uuid();
    const onProgress = createHandleProgress(id);
    const onError = createHandleError(id, onErrorCb)
    const onComplete = createOnComplete(id, worker, onCompleteCb)
    const validationError = validateFile(id, file, purpose)
    createUpload({ id, filename: file.name, purpose })

    if (validationError) {
      updateUpload({
        id,
        error: validationError
      })
      if (onErrorCb) {
        const upload = get().uploads?.[id];
        onErrorCb(upload);
      }
      return id;
    }
    const hash = await workerApi.hashFile(file);


    const { data: fileUploadData } = await apollo.mutate<CreateFileUploadMutation, CreateFileUploadMutationVariables>({
      mutation: CreateFileUploadDocument,
      variables: {
        hash,
        contentType: file.type,
        purpose,
      }
    });

    if (!fileUploadData?.createFileUpload) {
      throw new Error();
    }

    updateUpload({
      id,
      filename: fileUploadData.createFileUpload.filename,
      gsUri: fileUploadData.createFileUpload.gsUri
    });

    workerApi.uploadFile(
      fileUploadData.createFileUpload.uploadUrl,
      file,
      hash,
      proxy(onProgress),
      proxy(onError),
      proxy(onComplete)
    );

    return id;
  };

  return {
    uploads: {},
    uploadFile,
    removeUpload,
  }
})