import React, { FC } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';

interface MarkProps extends BoxProps {
  color?: string;
}

export const Mark: FC<MarkProps> = ({
  className,
  width,
  color,
  sx,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Box
      component={'svg'}
      sx={sx}
      className={className}
      viewBox='0 0 184 92'
      width={width || theme.spacing(19)}
      fill={color || theme.palette.text.primary}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48.4434 91.9863C46.1521 91.9863 44.2972 90.1352 44.2972 87.8485V4.22024C44.2972 2.47799 45.3883 0.953513 46.9159 0.300167C48.5525 -0.353178 50.2983 0.0823855 51.4985 1.38908L91.542 44.6188L131.585 1.38908C132.786 0.0823855 134.531 -0.244287 136.168 0.300167C137.805 0.953513 138.787 2.47799 138.787 4.22024V79.4639C138.787 81.7506 136.932 83.6017 134.641 83.6017C132.349 83.6017 130.494 81.7506 130.494 79.4639V14.8916L94.5971 53.6567C93.8333 54.5279 92.7422 54.9634 91.542 54.9634C90.3418 54.9634 89.2507 54.5279 88.4869 53.6567L52.6987 14.8916V87.8485C52.6987 90.1352 50.7348 91.9863 48.4434 91.9863Z'
        fill={color}
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M4.14619 66.9417C1.85488 66.9417 0 65.0906 0 62.8039V49.6281C0 47.3414 1.85488 45.4902 4.14619 45.4902C6.43751 45.4902 8.29239 47.3414 8.29239 49.6281V62.8039C8.4015 65.0906 6.54662 66.9417 4.14619 66.9417Z'
          fill={color}
        />
      </g>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M26.0787 77.7223C23.7874 77.7223 21.9325 75.8711 21.9325 73.5844V37.6504C21.9325 35.3637 23.7874 33.5126 26.0787 33.5126C28.37 33.5126 30.2249 35.3637 30.2249 37.6504V73.4755C30.2249 75.7622 28.37 77.7223 26.0787 77.7223Z'
          fill={color}
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M69.7221 81.316C67.4308 81.316 65.5759 79.4648 65.5759 77.1781V59.2111C65.5759 56.9244 67.4308 55.0732 69.7221 55.0732C72.0134 55.0732 73.8683 56.9244 73.8683 59.2111V77.0692C73.8683 79.3559 72.0134 81.316 69.7221 81.316Z'
          fill={color}
        />
      </g>
      <g opacity='0.61'>
        <path
          opacity='0.61'
          d='M113.365 81.3147C111.073 81.3147 109.218 79.4635 109.218 77.1768V64.001C109.218 61.7143 111.073 59.8632 113.365 59.8632C115.656 59.8632 117.511 61.7143 117.511 64.001V77.0679C117.62 79.3546 115.765 81.3147 113.365 81.3147Z'
          fill={color}
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M157.119 66.9414C154.827 66.9414 152.973 65.0902 152.973 62.8035V31.6607C152.973 29.374 154.827 27.5228 157.119 27.5228C159.41 27.5228 161.265 29.374 161.265 31.6607V62.6946C161.265 65.0902 159.41 66.9414 157.119 66.9414Z'
          fill={color}
        />
      </g>
      <g opacity='0.69'>
        <path
          opacity='0.69'
          d='M178.94 45.4899C176.649 45.4899 174.794 43.6388 174.794 41.3521V23.385C174.794 21.0983 176.649 19.2472 178.94 19.2472C181.232 19.2472 183.086 21.0983 183.086 23.385V41.3521C183.086 43.5299 181.232 45.4899 178.94 45.4899Z'
          fill={color}
        />
      </g>
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M91.5428 85.9969C89.2515 85.9969 87.3966 84.1458 87.3966 81.8591V74.6723C87.3966 72.3856 89.2515 70.5344 91.5428 70.5344C93.8341 70.5344 95.689 72.3856 95.689 74.6723V81.8591C95.7981 84.1458 93.8341 85.9969 91.5428 85.9969Z'
          fill={color}
        />
      </g>
      <g opacity='0.54'>
        <path
          opacity='0.54'
          d='M91.5428 25.1276C89.2515 25.1276 87.3966 23.2764 87.3966 20.9897V13.8029C87.3966 11.5162 89.2515 9.66504 91.5428 9.66504C93.8341 9.66504 95.689 11.5162 95.689 13.8029V20.9897C95.7981 23.2764 93.8341 25.1276 91.5428 25.1276Z'
          fill={color}
        />
      </g>
    </Box>
  );
};

Mark.defaultProps = {
  width: 184,
  color: '#FFF'
};
