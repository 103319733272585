import { makeVar, ReactiveVar } from '@apollo/client';
import { Product } from 'common/types/graphql-types';

export let selectedProduct: ReactiveVar<Partial<
  Product & { revisionId?: string }
> | null>;

export const initializeReactiveVariables = () => {
  selectedProduct = makeVar<Partial<Product & { revisionId?: string }> | null>(
    null
  );
};
