import { InMemoryCacheConfig } from '@apollo/client';
import {
  AuthFragment,
  AuthFragmentDoc
} from 'authentication/fragments/authFragment';
import { getApolloClient } from 'common/hooks/useApollo';
import { destroyCookie, setCookie } from 'nookies';

export const AUTH_ID = 'Auth:{}';
export const AUTH_COOKIE_NAME = 'maastr-auth';

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        authUser: {
          read(existing, { cache }) {
            const authUserFragment = cache.readFragment<AuthFragment>({
              id: AUTH_ID,
              fragment: AuthFragmentDoc
            });
            if (authUserFragment) {
              setCookie(
                null,
                AUTH_COOKIE_NAME,
                JSON.stringify(authUserFragment),
                {
                  path: '/',
                  expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
                }
              );
            }
            return authUserFragment;
          }
        }
      }
    },
    Mutation: {
      fields: {
        authenticate: {
          merge(existing, incoming, { cache }) {
            const authUserFragment = cache.readFragment<AuthFragment>({
              id: AUTH_ID,
              fragment: AuthFragmentDoc
            });
            setCookie(
              null,
              AUTH_COOKIE_NAME,
              JSON.stringify(authUserFragment),
              {
                path: '/',
                expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
              }
            );
            return incoming;
          }
        },
        logout: {
          async merge(existing, incoming, { cache }) {
            destroyCookie(null, AUTH_COOKIE_NAME);
            const client = getApolloClient();
            await client.clearStore();
            return incoming;
          }
        }
      }
    },
    Auth: {
      keyFields: []
    }
  }
};
