import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
export type BaseUserFragment = { __typename?: 'User', id: string, avatar?: string | null | undefined, firstName: string, lastName: string, username: string };

export const BaseUserFragmentDoc = gql`
    fragment BaseUser on User {
  id
  avatar
  firstName
  lastName
  username
}
    `;