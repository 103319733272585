import getConfig from 'next/config';
import posthog from 'posthog-js';
const { publicRuntimeConfig } = getConfig();

if (typeof window !== 'undefined') {
  posthog.init(publicRuntimeConfig.posthogApiKey);
}

export const useAnalytics = () => {
  return posthog;
};
