export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type. */
  Date: number;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  Email: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: Record<string, unknown>;
  /** MD5 custom scalar type. */
  MD5: any;
  /** MimeType custom scalar type. */
  MimeType: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
  /** UUID custom scalar type. */
  UUID: string;
};

export type Album = {
  __typename?: 'Album';
  allowDownload: Scalars['Boolean'];
  artist?: Maybe<Scalars['String']>;
  artwork?: Maybe<Scalars['URL']>;
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canInvite: Scalars['Boolean'];
  canUpload: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  creator: User;
  id: Scalars['UUID'];
  isDisabled: Scalars['Boolean'];
  isMine: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  members?: Maybe<Array<ShareableMember>>;
  myInvite?: Maybe<ShareableMember>;
  title: Scalars['String'];
  trackCount: Scalars['Int'];
  tracks: TrackPage;
  updatedAt: Scalars['Date'];
};


export type AlbumTracksArgs = {
  input?: InputMaybe<TrackPageInput>;
};

export type AlbumBoolean = {
  __typename?: 'AlbumBoolean';
  status: Scalars['Boolean'];
};

export type AlbumMutationResponse = {
  __typename?: 'AlbumMutationResponse';
  album: Album;
};

export type AlbumPage = {
  __typename?: 'AlbumPage';
  next?: Maybe<Scalars['String']>;
  results: Array<AlbumSearchResult>;
};

export type AlbumPageInput = {
  filter?: InputMaybe<ProjectFilter>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProjectSort>;
  term?: InputMaybe<Scalars['String']>;
};

export type AlbumSearchMatches = {
  __typename?: 'AlbumSearchMatches';
  artist?: Maybe<SearchMatch>;
  title?: Maybe<SearchMatch>;
  tracks?: Maybe<SearchMatch>;
};

export type AlbumSearchResult = {
  __typename?: 'AlbumSearchResult';
  album: Album;
  matches: AlbumSearchMatches;
};

export type AudioData = {
  __typename?: 'AudioData';
  mp3?: Maybe<Scalars['URL']>;
  mp3Filename?: Maybe<Scalars['String']>;
  wav?: Maybe<Scalars['URL']>;
  wavFilename?: Maybe<Scalars['String']>;
};

export type Auth = {
  __typename?: 'Auth';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export enum AuthMethod {
  Password = 'PASSWORD',
  RefreshToken = 'REFRESH_TOKEN'
}

export enum AuthRole {
  Admin = 'ADMIN',
  BetaTester = 'BETA_TESTER',
  Moderator = 'MODERATOR',
  SubTier_1 = 'SUB_TIER_1',
  SubTier_2 = 'SUB_TIER_2',
  SubUnlimited = 'SUB_UNLIMITED',
  Superadmin = 'SUPERADMIN',
  User = 'USER'
}

export type AuthenticateInput = {
  authMethod?: InputMaybe<AuthMethod>;
  identifier: Scalars['String'];
  secret: Scalars['String'];
};

export type BugBoolean = {
  __typename?: 'BugBoolean';
  status: Scalars['Boolean'];
};

export enum CanDownload {
  All = 'ALL',
  Mp3 = 'MP3',
  None = 'NONE'
}

export type Card = {
  __typename?: 'Card';
  brand: CardBrand;
  expiration: Scalars['String'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  last4: Scalars['String'];
};

export enum CardBrand {
  Amex = 'AMEX',
  Diner = 'DINER',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unionpay = 'UNIONPAY',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type Comment = {
  __typename?: 'Comment';
  children?: Maybe<CommentPage>;
  createdAt: Scalars['Date'];
  creator: User;
  endTime?: Maybe<Scalars['Float']>;
  hasChildren: Scalars['Boolean'];
  id: Scalars['String'];
  myVote?: Maybe<Vote>;
  parent?: Maybe<Comment>;
  revision: Revision;
  startTime?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
  votes?: Maybe<VotePage>;
};


export type CommentChildrenArgs = {
  input?: InputMaybe<CommentPageInput>;
};


export type CommentVotesArgs = {
  input?: InputMaybe<VotePageInput>;
};

export type CommentBoolean = {
  __typename?: 'CommentBoolean';
  status: Scalars['Boolean'];
};

export type CommentPage = {
  __typename?: 'CommentPage';
  next?: Maybe<Scalars['String']>;
  results: Array<Comment>;
};

export type CommentPageInput = {
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  term?: InputMaybe<Scalars['String']>;
};

export type Component = {
  __typename?: 'Component';
  apiId: Scalars['String'];
  attributes: Array<ComponentAttribute>;
  category: Scalars['String'];
  component: Scalars['String'];
  uid: Scalars['String'];
};

export type ComponentAttribute = {
  __typename?: 'ComponentAttribute';
  allowedTypes?: Maybe<Array<Scalars['String']>>;
  component?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  enum?: Maybe<Array<Scalars['String']>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  repeatable?: Maybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
  type: Scalars['String'];
};

export type CreateAlbumInput = {
  artist?: InputMaybe<Scalars['String']>;
  artwork?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateBugInput = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type CreateCommentInput = {
  endTime?: InputMaybe<Scalars['Float']>;
  parent?: InputMaybe<Scalars['UUID']>;
  revision: Scalars['UUID'];
  startTime?: InputMaybe<Scalars['Float']>;
  text: Scalars['String'];
};

export type CreateFileUploadInput = {
  contentType: Scalars['MimeType'];
  hash: Scalars['MD5'];
  purpose: FileUploadPurpose;
};

export type CreateInviteInput = {
  email: Scalars['Email'];
  id: Scalars['UUID'];
  objectType: ShareableObjectType;
};

export type CreatePageInput = {
  slug: Scalars['String'];
};

export type CreateRevisionInput = {
  bypass?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  original: Scalars['URL'];
  settings?: InputMaybe<RevisionSettingsInput>;
  track: Scalars['UUID'];
  useV2Engine?: InputMaybe<Scalars['Boolean']>;
};

export type CreateTrackInput = {
  album: Scalars['UUID'];
  artist?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CreateVoteInput = {
  comment: Scalars['UUID'];
  status: VoteStatus;
};

export type DeleteShareableMemberInput = {
  id: Scalars['UUID'];
};

export type Discount = {
  __typename?: 'Discount';
  amount: Scalars['Float'];
  name: Scalars['String'];
};

export type FileUpload = {
  __typename?: 'FileUpload';
  filename: Scalars['String'];
  gsUri: Scalars['URL'];
  uploadUrl: Scalars['URL'];
};

export enum FileUploadPurpose {
  Avatar = 'AVATAR',
  Content = 'CONTENT',
  ProjectArtwork = 'PROJECT_ARTWORK',
  Track = 'TRACK'
}

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float'];
  id: Scalars['String'];
  items: Array<InvoiceLineItem>;
  status?: Maybe<Scalars['String']>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  amount: Scalars['Float'];
  description: Scalars['String'];
  discounts?: Maybe<Array<Discount>>;
  id: Scalars['String'];
};

export type MarkFileAsPublicInput = {
  filename: Scalars['String'];
  purpose: FileUploadPurpose;
};

export type Mutation = {
  __typename?: 'Mutation';
  acknowledge: UserBoolean;
  authenticate: Auth;
  cancelSubscription: PaymentBoolean;
  createAlbum: AlbumMutationResponse;
  createComment: Comment;
  createFileUpload: FileUpload;
  createPage: Page;
  createRevision: RevisionMutationResponse;
  createTrack: TrackMutationResponse;
  createUser: User;
  createVote: Vote;
  deleteAlbum: AlbumBoolean;
  deleteComment: CommentBoolean;
  deleteNotification: NotificationBoolean;
  deletePayment: PaymentBoolean;
  deleteRevision: RevisionBoolean;
  deleteShareableMember: Scalars['Boolean'];
  deleteTrack: TrackBoolean;
  inviteMember: ShareableMember;
  logout: Scalars['Boolean'];
  markAsSeen: NotificationBoolean;
  markFileAsPublic: PublicFile;
  purchaseProduct: PurchaseProductOutput;
  reportBug: BugBoolean;
  requestPasswordReset: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  respondToInvite: ShareableMember;
  setUserRoles: Scalars['Boolean'];
  submitSurvey: SurveyBoolean;
  updateAlbum: AlbumMutationResponse;
  updatePage: Page;
  updatePayment: PaymentBoolean;
  updateRevision: RevisionMutationResponse;
  updateTrack: TrackMutationResponse;
  updateUser: User;
};


export type MutationAcknowledgeArgs = {
  key: Scalars['String'];
};


export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


export type MutationCreateAlbumArgs = {
  input: CreateAlbumInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateFileUploadArgs = {
  input: CreateFileUploadInput;
};


export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


export type MutationCreateRevisionArgs = {
  input: CreateRevisionInput;
};


export type MutationCreateTrackArgs = {
  input: CreateTrackInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVoteArgs = {
  input: CreateVoteInput;
};


export type MutationDeleteAlbumArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['UUID'];
};


export type MutationDeletePaymentArgs = {
  paymentMethod: Scalars['String'];
};


export type MutationDeleteRevisionArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteShareableMemberArgs = {
  input: DeleteShareableMemberInput;
};


export type MutationDeleteTrackArgs = {
  id: Scalars['UUID'];
};


export type MutationInviteMemberArgs = {
  input: CreateInviteInput;
};


export type MutationMarkAsSeenArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationMarkFileAsPublicArgs = {
  input: MarkFileAsPublicInput;
};


export type MutationPurchaseProductArgs = {
  input: PurchaseProductInput;
};


export type MutationReportBugArgs = {
  input: CreateBugInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRespondToInviteArgs = {
  input: RespondToInviteInput;
};


export type MutationSetUserRolesArgs = {
  input: UpdateUserRolesInput;
};


export type MutationSubmitSurveyArgs = {
  input: SubmitSurveyInput;
};


export type MutationUpdateAlbumArgs = {
  input: UpdateAlbumInput;
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};


export type MutationUpdateRevisionArgs = {
  input: UpdateRevisionInput;
};


export type MutationUpdateTrackArgs = {
  input: UpdateTrackInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  album?: Maybe<Album>;
  comment?: Maybe<Comment>;
  createdAt: Scalars['Date'];
  data: NotificationData;
  from?: Maybe<User>;
  id: Scalars['String'];
  revision?: Maybe<Revision>;
  seen: Scalars['Boolean'];
  shareableMember?: Maybe<Shareable>;
  to: User;
  track?: Maybe<Track>;
  type: NotificationType;
  updatedAt: Scalars['Date'];
};

export type NotificationBoolean = {
  __typename?: 'NotificationBoolean';
  status: Scalars['Boolean'];
};

export type NotificationData = {
  __typename?: 'NotificationData';
  _?: Maybe<Scalars['String']>;
};

export type NotificationPage = {
  __typename?: 'NotificationPage';
  next?: Maybe<Scalars['String']>;
  results: Array<Notification>;
  unseenCount: Scalars['Int'];
};

export type NotificationPageInput = {
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export enum NotificationType {
  Comment = 'COMMENT',
  Invite = 'INVITE',
  InviteAccepted = 'INVITE_ACCEPTED',
  Lock = 'LOCK',
  Mention = 'MENTION',
  NewRevision = 'NEW_REVISION',
  PasswordResetRequest = 'PASSWORD_RESET_REQUEST',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentProcessed = 'PAYMENT_PROCESSED',
  Reply = 'REPLY',
  RevisionProcessed = 'REVISION_PROCESSED',
  Unlock = 'UNLOCK'
}

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float'];
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  paid: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  allowedComponents: Array<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['URL']>;
  backgroundOverlayImage?: Maybe<Scalars['URL']>;
  content: Array<Scalars['JSON']>;
  head: Array<Scalars['JSON']>;
  id: Scalars['String'];
  publishedAt?: Maybe<Scalars['Date']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PaymentBoolean = {
  __typename?: 'PaymentBoolean';
  status: Scalars['Boolean'];
};

export type PreviewInvoiceInput = {
  coupon?: InputMaybe<Scalars['String']>;
  price: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Float'];
  id: Scalars['String'];
  interval?: Maybe<PriceInterval>;
};

export enum PriceInterval {
  Month = 'MONTH',
  Year = 'YEAR'
}

export type Product = {
  __typename?: 'Product';
  calloutText?: Maybe<Scalars['String']>;
  features: Array<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  prices: Array<Price>;
  type: ProductType;
};

export type ProductPage = {
  __typename?: 'ProductPage';
  next?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Product>>;
};

export type ProductPageInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  interval?: InputMaybe<PriceInterval>;
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProductType>;
};

export enum ProductType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

export enum ProjectFilter {
  All = 'ALL',
  Invited = 'INVITED',
  Mine = 'MINE'
}

export enum ProjectSort {
  Artist = 'ARTIST',
  Last = 'LAST',
  Title = 'TITLE'
}

export type PublicFile = {
  __typename?: 'PublicFile';
  filename: Scalars['String'];
  publicUrl: Scalars['URL'];
};

export type PurchaseProductInput = {
  coupon?: InputMaybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  price: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['UUID']>;
};

export type PurchaseProductOutput = {
  __typename?: 'PurchaseProductOutput';
  invoice: Invoice;
  revision?: Maybe<Revision>;
  user?: Maybe<User>;
};

export type Query = {
  __typename?: 'Query';
  album: Album;
  albums: AlbumPage;
  comment: Comment;
  components: Array<Component>;
  me: User;
  page: Page;
  pages: Array<Page>;
  previewInvoice: Invoice;
  product: Product;
  products: ProductPage;
  revision: Revision;
  shareableMember: ShareableMember;
  track: Track;
  user: User;
  users: UserPage;
};


export type QueryAlbumArgs = {
  id: Scalars['UUID'];
};


export type QueryAlbumsArgs = {
  input?: InputMaybe<AlbumPageInput>;
};


export type QueryCommentArgs = {
  id: Scalars['UUID'];
};


export type QueryPageArgs = {
  slug: Scalars['String'];
};


export type QueryPreviewInvoiceArgs = {
  input: PreviewInvoiceInput;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  input?: InputMaybe<ProductPageInput>;
};


export type QueryRevisionArgs = {
  id: Scalars['UUID'];
};


export type QueryShareableMemberArgs = {
  token: Scalars['String'];
};


export type QueryTrackArgs = {
  id: Scalars['UUID'];
};


export type QueryUserArgs = {
  id: Scalars['UUID'];
};


export type QueryUsersArgs = {
  input: UserPageInput;
};

export type RequestPasswordResetInput = {
  email: Scalars['Email'];
};

export type ResetPasswordInput = {
  id?: InputMaybe<Scalars['UUID']>;
  newPassword: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type RespondToInviteInput = {
  isAccepted: Scalars['Boolean'];
  token: Scalars['String'];
};

export type Revision = {
  __typename?: 'Revision';
  canDelete: Scalars['Boolean'];
  canDownload: CanDownload;
  canLock: Scalars['Boolean'];
  canPlaythrough: Scalars['Boolean'];
  canSetPrimary: Scalars['Boolean'];
  canUnlock: Scalars['Boolean'];
  comments?: Maybe<CommentPage>;
  createdAt: Scalars['Date'];
  id: Scalars['UUID'];
  index: Scalars['Float'];
  isLocked: Scalars['Boolean'];
  isMine: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  isProcessed: Scalars['Boolean'];
  master?: Maybe<AudioData>;
  members: Array<ShareableMember>;
  name?: Maybe<Scalars['String']>;
  original?: Maybe<AudioData>;
  settings?: Maybe<RevisionSettings>;
  status: RevisionStatus;
  track: Track;
  updatedAt: Scalars['Date'];
};


export type RevisionCommentsArgs = {
  input?: InputMaybe<CommentPageInput>;
};


export type RevisionMembersArgs = {
  input?: InputMaybe<ShareableMemberListInput>;
};

export type RevisionBoolean = {
  __typename?: 'RevisionBoolean';
  status: Scalars['Boolean'];
};

export enum RevisionColors {
  Bright = 'BRIGHT',
  Dark = 'DARK',
  Neutral = 'NEUTRAL'
}

export enum RevisionDynamics {
  Gentle = 'GENTLE',
  Loud = 'LOUD',
  Normal = 'NORMAL'
}

export type RevisionMutationResponse = {
  __typename?: 'RevisionMutationResponse';
  revision: Revision;
};

export type RevisionPage = {
  __typename?: 'RevisionPage';
  next?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Revision>>;
};

export type RevisionPageInput = {
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  term?: InputMaybe<Scalars['String']>;
};

export type RevisionSettings = {
  __typename?: 'RevisionSettings';
  colors: RevisionColors;
  dynamics: RevisionDynamics;
};

export type RevisionSettingsInput = {
  colors: RevisionColors;
  dynamics: RevisionDynamics;
};

export enum RevisionStatus {
  Balancing = 'BALANCING',
  Bypass = 'BYPASS',
  Created = 'CREATED',
  Dynamics = 'DYNAMICS',
  Invalid = 'INVALID',
  Locked = 'LOCKED',
  Mastered = 'MASTERED',
  Spectral = 'SPECTRAL',
  Uploaded = 'UPLOADED'
}

export enum RoleOperation {
  Grant = 'GRANT',
  Revoke = 'REVOKE'
}

export type SearchMatch = {
  __typename?: 'SearchMatch';
  length: Scalars['Int'];
  start: Scalars['Int'];
};

export type Shareable = {
  __typename?: 'Shareable';
  album?: Maybe<Album>;
  createdAt: Scalars['Date'];
  id: Scalars['UUID'];
  track?: Maybe<Track>;
  updatedAt: Scalars['Date'];
};

export type ShareableMember = {
  __typename?: 'ShareableMember';
  canDelete: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  email?: Maybe<Scalars['Email']>;
  id: Scalars['UUID'];
  isAccepted: Scalars['Boolean'];
  shareable?: Maybe<Shareable>;
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  user?: Maybe<User>;
};

export enum ShareableMemberListFilter {
  Accepted = 'ACCEPTED'
}

export type ShareableMemberListInput = {
  filter?: InputMaybe<ShareableMemberListFilter>;
};

export enum ShareableObjectType {
  Album = 'ALBUM'
}

export type SubmitSurveyInput = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  albumUpdated: Album;
  commentAdded: Comment;
  commentUpdated: Comment;
  notificationAdded: Notification;
  revisionUpdated: Revision;
  trackUpdated: Track;
};


export type SubscriptionAlbumUpdatedArgs = {
  album: Scalars['UUID'];
};


export type SubscriptionCommentAddedArgs = {
  revision: Scalars['UUID'];
};


export type SubscriptionCommentUpdatedArgs = {
  comment: Scalars['UUID'];
};


export type SubscriptionRevisionUpdatedArgs = {
  revision: Scalars['UUID'];
};


export type SubscriptionTrackUpdatedArgs = {
  track: Scalars['UUID'];
};

export type SurveyBoolean = {
  __typename?: 'SurveyBoolean';
  status: Scalars['Boolean'];
};

export type Track = {
  __typename?: 'Track';
  album?: Maybe<Album>;
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  creator: User;
  hasPendingRevisions: Scalars['Boolean'];
  id: Scalars['UUID'];
  index: Scalars['Int'];
  isPublic?: Maybe<Scalars['Boolean']>;
  myInvite?: Maybe<ShareableMember>;
  next?: Maybe<Track>;
  pendingRevisions: Array<Revision>;
  prev?: Maybe<Track>;
  primaryRevision?: Maybe<Revision>;
  revisions: RevisionPage;
  status?: Maybe<RevisionStatus>;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
};


export type TrackRevisionsArgs = {
  input?: InputMaybe<RevisionPageInput>;
};

export type TrackBoolean = {
  __typename?: 'TrackBoolean';
  status: Scalars['Boolean'];
};

export type TrackMutationResponse = {
  __typename?: 'TrackMutationResponse';
  track: Track;
};

export type TrackPage = {
  __typename?: 'TrackPage';
  next?: Maybe<Scalars['String']>;
  results: Array<Track>;
};

export type TrackPageInput = {
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  term?: InputMaybe<Scalars['String']>;
};

export type UpdateAlbumInput = {
  allowDownload?: InputMaybe<Scalars['Boolean']>;
  artist?: InputMaybe<Scalars['String']>;
  artwork?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdatePageInput = {
  backgroundImage?: InputMaybe<Scalars['URL']>;
  backgroundOverlayImage?: InputMaybe<Scalars['URL']>;
  content?: InputMaybe<Array<Scalars['JSON']>>;
  head?: InputMaybe<Array<Scalars['JSON']>>;
  published?: InputMaybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentInput = {
  makeDefault: Scalars['Boolean'];
  paymentMethod: Scalars['String'];
};

export type UpdateRevisionInput = {
  id: Scalars['UUID'];
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTrackInput = {
  album?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  index?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['URL']>;
  email?: InputMaybe<Scalars['Email']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastName?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRolesInput = {
  id: Scalars['UUID'];
  operation: RoleOperation;
  roles: Array<AuthRole>;
};

export type User = {
  __typename?: 'User';
  acknowledgments: Array<Scalars['String']>;
  affiliate?: Maybe<Scalars['String']>;
  albums: AlbumPage;
  avatar?: Maybe<Scalars['URL']>;
  canUpdateDynamics: Scalars['Boolean'];
  cards: Array<Card>;
  defaultCard?: Maybe<Card>;
  email: Scalars['Email'];
  emails: Array<UserEmail>;
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  isAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  notifications: NotificationPage;
  orders?: Maybe<Array<Order>>;
  pendingRevisions?: Maybe<RevisionPage>;
  requirePasswordReset: Scalars['Boolean'];
  roles: Array<AuthRole>;
  subscription?: Maybe<UserSubscription>;
  tracks: TrackPage;
  username: Scalars['String'];
};


export type UserAlbumsArgs = {
  input?: InputMaybe<AlbumPageInput>;
};


export type UserNotificationsArgs = {
  input?: InputMaybe<NotificationPageInput>;
};


export type UserPendingRevisionsArgs = {
  input?: InputMaybe<RevisionPageInput>;
};


export type UserTracksArgs = {
  input?: InputMaybe<TrackPageInput>;
};

export type UserBoolean = {
  __typename?: 'UserBoolean';
  status: Scalars['Boolean'];
};

export type UserEmail = {
  __typename?: 'UserEmail';
  email: Scalars['Email'];
  primary: Scalars['Boolean'];
};

export type UserPage = {
  __typename?: 'UserPage';
  next?: Maybe<Scalars['String']>;
  results: Array<User>;
};

export type UserPageInput = {
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  cancelAtPeriodEnd: Scalars['Boolean'];
  product: Product;
};

export type Vote = {
  __typename?: 'Vote';
  comment: Comment;
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  status: VoteStatus;
  updatedAt: Scalars['Date'];
  user: User;
};

export type VotePage = {
  __typename?: 'VotePage';
  agrees?: Maybe<Scalars['Int']>;
  disagrees?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  results: Array<Vote>;
};

export type VotePageInput = {
  page?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  term?: InputMaybe<Scalars['String']>;
};

export enum VoteStatus {
  Agree = 'AGREE',
  Disagree = 'DISAGREE'
}
