import { InMemoryCacheConfig } from '@apollo/client';

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Mutation: {
      fields: {
        respondToInvite: {
          merge(existing, incoming, { args, cache, readField }) {
            const albumId = readField('id', readField(
              'album',
              readField('shareable', incoming)
            ));

            if (!args?.input?.isAccepted) {
              cache.evict({
                id: cache.identify({
                  __typename: 'Album',
                  id: albumId
                })
              });
            }
            return incoming;
          }
        },
        deleteShareableMember: {
          merge(existing, incoming, { args, cache }) {
            cache.evict({
              id: cache.identify({
                __typename: 'ShareableMember',
                id: args!.input!.id
              })
            });
            return incoming;
          }
        }
      }
    }
  }
};
