import { BaseRevisionFragment } from "track/fragments/baseRevision";
import { Version } from "track/state/currentRevision";

export const getRevisionSource = (revision: Pick<BaseRevisionFragment, 'master' | 'original'>, version?: Version) => {
  if (version) {
    return (
      revision?.[version]?.mp3 ||
      revision?.[version]?.wav || null)
  }

  return (revision.master?.mp3 ||
    revision.original?.mp3 ||
    revision.original?.wav ||
    null)
}