import {
  FieldMergeFunction,
  InMemoryCacheConfig,
  Reference
} from '@apollo/client';
import { RevisionColors, RevisionDynamics } from 'common/types/graphql-types';
import { BaseCommentFragment } from 'track/fragments/baseComment';
import {
  BaseRevisionFragment,
  BaseRevisionFragmentDoc
} from 'track/fragments/baseRevision';
import { MeDocument, MeQuery } from 'user/queries/me';

const mergeCommentPage: FieldMergeFunction = (
  existing,
  incoming,
  { readField }
) => {
  const results: BaseCommentFragment[] = [];
  const uniqueObjectsSet = new Set();
  for (const object of [
    ...(existing?.results || []),
    ...(incoming?.results || [])
  ]) {
    if (!uniqueObjectsSet.has(object.__ref)) {
      results.push(object);
    }
    uniqueObjectsSet.add(object.__ref);
  }

  const sorted = results.sort((a, b) => {
    return (readField('createdAt', a) || 0) < (readField('createdAt', b) || 0)
      ? 1
      : -1;
  });
  return {
    ...incoming,
    next: existing?.next === null ? null : incoming?.next || null,
    results: sorted
  };
};

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Mutation: {
      fields: {
        deleteComment: {
          merge(existing, incoming, { args, cache }) {
            cache.evict({
              id: cache.identify({ __typename: 'Comment', id: args!.id })
            });
            return incoming;
          }
        },
        deleteTrack: {
          merge(existing, incoming, { args, cache }) {
            const meQeury = cache.readQuery<MeQuery>({
              query: MeDocument
            });

            for (const revision of meQeury?.me?.pendingRevisions?.results ||
              []) {
              if (!revision.track?.id === args!.id) {
                continue;
              }
              cache.evict({
                id: cache.identify({ __typename: 'Revision', id: revision.id })
              });
            }

            cache.evict({
              id: cache.identify({ __typename: 'Track', id: args!.id })
            });
            return incoming;
          }
        },
        deleteRevision: {
          merge(existing, incoming, { args, cache }) {
            const meQeury = cache.readQuery<MeQuery>({
              query: MeDocument
            });

            for (const revision of meQeury?.me?.pendingRevisions?.results ||
              []) {
              if (!revision?.id === args!.id) {
                continue;
              }
              cache.evict({
                id: cache.identify({ __typename: 'Revision', id: revision.id })
              });
            }

            cache.evict({
              id: cache.identify({ __typename: 'Revision', id: args!.id })
            });
            return incoming;
          }
        },
        updateTrack: {
          keyArgs: false,
          merge(existing, incoming, { args, cache, readField }) {
            if (args?.input?.album) {
              const trackAlbum = readField(
                'id',
                readField('album', incoming?.track)
              ) as { results?: Reference[] };
              cache.evict({
                id: cache.identify({ __typename: 'Album', id: trackAlbum }),
                fieldName: 'tracks'
              });
            }
            return incoming;
          }
        },
        updateRevision: {
          keyArgs: false,
          merge(existing, incoming, { args, cache, readField }) {
            if (args?.input?.isPrimary) {
              const trackRevisionPage = readField(
                'revisions',
                readField('track', incoming?.revision)
              ) as { results?: Reference[] };

              if (!trackRevisionPage!.results) {
                return incoming;
              }

              for (const revision of trackRevisionPage?.results) {
                if (
                  !readField('isPrimary', revision) ||
                  readField('id', revision) ===
                    readField('id', incoming?.revision)
                ) {
                  continue;
                }
                cache.writeFragment({
                  id: revision.__ref,
                  fragmentName: 'BaseRevision',
                  fragment: BaseRevisionFragmentDoc,
                  data: {
                    ...(cache.readFragment({
                      id: revision.__ref,
                      fragmentName: 'BaseRevision',
                      fragment: BaseRevisionFragmentDoc
                    }) as BaseRevisionFragment),
                    isPrimary: false
                  }
                });
              }
            }
            return incoming;
          }
        },
        createRevision: {
          keyArgs: false,
          merge(existing, incoming, { cache, readField }) {
            cache.evict({
              id: readField('track', incoming?.revision)
            });
            return incoming;
          }
        }
      }
    },
    Query: {
      fields: {
        comment(_, { args, toReference }) {
          return toReference({
            __typename: 'Comment',
            id: args!.id
          });
        },
        vote(_, { args, toReference }) {
          return toReference({
            __typename: 'Vote',
            id: args!.id
          });
        },
        revision(_, { args, toReference }) {
          return toReference({
            __typename: 'Revision',
            id: args!.id
          });
        },
        track(_, { args, toReference }) {
          return toReference({
            __typename: 'Track',
            id: args!.id
          });
        }
      }
    },
    Comment: {
      fields: {
        children: {
          keyArgs: false,
          merge: mergeCommentPage
        }
      }
    },
    Track: {
      fields: {
        revisions: {
          keyArgs: false,
          merge(existing, incoming) {
            const results: BaseRevisionFragment[] = [];
            const uniqueObjectsSet = new Set();
            for (const object of [
              ...(existing?.results || []),
              ...(incoming?.results || [])
            ]) {
              if (!uniqueObjectsSet.has(object.__ref)) {
                results.push(object);
              }
              uniqueObjectsSet.add(object.__ref);
            }
            return {
              ...incoming,
              next: existing?.next === null ? null : incoming?.next || null,
              results
            };
          }
        }
      }
    },
    RevisionSettings: {
      // keyFields: ['dynamics'],
      fields: {
        colors: {
          merge(existing, incoming) {
            return incoming || RevisionColors.Neutral;
          }
        },
        dynamics: {
          merge(existing, incoming) {
            return incoming || RevisionDynamics.Normal;
          }
        }
      }
    },
    Revision: {
      fields: {
        comments: {
          keyArgs: false,
          merge: mergeCommentPage
        }
      }
    }
  }
};
